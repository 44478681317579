<template>
  <div v-has-perms="rolePerm">
    <quotation-edit-form
      :id="detail.id"
      :form-editable-update="formEditable"
      :errors="errors"
      :reset-form="resetForm"
      @set-form="updateForm"
      :proposal="proposalDetails"
      :is-yard-quotation="isYardQuotation"
      @change-owner="changeOwner = !changeOwner"
      @create-old-quotation="createOldQuotation"
    />
    <biling-form
      :id="formInvoiceAddress.id"
      :validator-payment-forms="errors['payment_forms']"
      :payment-forms-update="form.payment_forms"
      :proposal="proposalDetails"
      :owner="form.customer"
      :owner_type="form.customer_typology"
      :form-editable-update="formEditable"
      :reset-invoice-address="resetInvoiceAddress"
      :change-owner="changeOwner"
      @update-form="updateForm({ ...form, payment_forms: $event })"
      @update-form-invoice-address="updateFormInvoiceAddress"
      :errors="{}"
    />
    <services-manager
      :quotation-rows="getQuotationRows"
      :reset-quotation-rows="resetQuotationRows"
      :form-editable-update="formEditable"
      @update-services="updateServices"
    />
    <quotation-installment-manager
      v-if="initializeFormInstallment"
      :total-amount="totalAmount"
      :total-income="totalIncome"
      :form-editable-update="installmentFormEditable"
      :is-create-old-quotation="isCreateOldQuotation"
      :quotation-installments="quotationInstallmentList"
      :reset-quotation-installments="resetQuotationInstallments"
      @update-installments="updateInstallments"
    />
    <div class="panel-actions">
      <b-button
        size="lg"
        class="mr-3"
        @click.prevent="formEditable ? cancel() : goToList()"
      >
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
          v-if="!this.detail || !this.detail.order_done "
          size="lg"
          variant="primary"
          v-has-perms="editRolePerm"
          :disabled="formEditable ? $v.$invalid || invalidServices : false"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
      >
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
      <b-button
          v-if="this.detail && this.detail.order_done "
          size="lg"
          variant="primary"
          v-has-perms="installmentFormEditRolePerm"
          :disabled="installmentFormEditable ? $v.formQuotationInstallment.$invalid : false"
          @click.prevent="installmentFormEditable ? onSubmitInstallmentForm() : makeInstallmentFormEditable()"
      >
        {{ installmentFormEditable ? 'Save' : 'Edit Installment' | translate }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as QUOTATION_TYPES } from './store'
import { ROUTES as QUOTATION_ROUTES } from './router'
import { TYPES as PROPOSAL_TYPES } from '../../proposal/proposal/store'
import { ROUTES as PROPOSAL_ROUTES } from '../../proposal/proposal/router'

import { TYPES as CUSTOMER_INVOICE_ADDRESS_TYPES } from '../customer-invoice-address/store'
import { TYPES as QUOTATION_ROW_TYPES } from '../quotation-row/store'
import { TYPES as QUOTATION_INSTALLMENT_TYPES } from '../quotation-installment/store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import QuotationEditForm from './components/quotation-form/QuotationEditForm'
import ServicesManager from './components/quotation-row-component/ServicesManager'
import BillingForm from './components/customer-invoice-address-form/BillingForm'
import QuotationInstallmentManager from './components/quotation-installment-form/QuotationInstallmentManager'
import { checkIfEqual, PAYMENT_FORM_DEFAULT_CODE } from '../../../const'
import { TYPES as ORGANIZATION_TYPES } from '../../organization/organization/store'
import AddressScopeMixin from '../../organization/organization/organization-address/mixins/AddressScopeMixin'
import PaymentFormMixin from './mixins/PaymentFormsMixin'
import ClientsMixin from '../../proposal/proposal/mixins/ClientsMixin'

export default {
  name: 'QuotationEdit',
  mixins: [ThuxDetailPanelMixin, AddressScopeMixin, PaymentFormMixin, ClientsMixin],
  components: {
    'quotation-edit-form': QuotationEditForm,
    'services-manager': ServicesManager,
    'biling-form': BillingForm,
    'quotation-installment-manager': QuotationInstallmentManager
  },
  data () {
    return {
      pageName: 'Quotation',
      installmentFormEditable: false,
      isCreateOldQuotation: false,

      // Permissions
      rolePerm: 'quotation_quotation_retrieve',
      editRolePerm: 'quotation_quotation_update', // TODO non funziona
      installmentFormEditRolePerm: 'quotation_quotationinstallment_update', // TODO non funziona

      // FORM
      form: {},
      formInitial: {},
      isFormChanged: false,
      resetForm: false,
      changeOwner: false,

      // FORM INVOICE
      formInvoiceAddress: {},
      formInvoiceAddressInitial: {},
      isFormInvoiceAddressChanged: false,
      resetInvoiceAddress: false,

      // FORM QUOTATION ROWS
      formQuotationRows: [],
      formQuotationRowsInitial: [],
      quotationRowsToDeleted: new Set(),
      quotationRowsToChanged: new Set(),
      isFormQuotationRowsChanged: false,
      invalidServices: true,
      resetQuotationRows: false,

      // FORM INSTALLMENT
      formQuotationInstallment: [],
      formQuotationInstallmentInitial: [],
      quotationInstallmentsToDeleted: new Set(),
      quotationInstallmentsToChanged: new Set(),
      isFormQuotationInstallmentChanged: false,
      initializeFormInstallment: false,
      resetQuotationInstallments: false,
      totalAmount: 0,
      totalIncome: 0
    }
  },
  created () {
    this.$set(this, 'formEditable', false)
    this.$set(this, 'installmentFormEditable', false)
    if (!this.id) {
      this.$set(this, 'formEditable', true)
      this.$set(this, 'installmentFormEditable', true)
    }
    this.setDetail({})
    this.cleanAllDataInvoiceAddress()
    this.cleanAllDataQuotationRowList()
    this.cleanAllDataQuotationInstallmentList()
  },
  computed: {
    ...mapGetters({
      ...QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.GETTERS,
      proposalDetails: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.GETTERS.detail,
      customerInvoiceAddress: CUSTOMER_INVOICE_ADDRESS_TYPES.GENERIC.quotation.customerinvoiceaddress.DETAIL.GETTERS.detail,
      quotationRowsList: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.LIST.GETTERS.list,
      customerDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail,
      quotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.GETTERS.list
    }),
    checkVatOrSsn () {
      if (this.formInvoiceAddress.ssn && this.formInvoiceAddress.ssn !== '') return true
      if (this.formInvoiceAddress.vat_number && this.formInvoiceAddress.vat_number !== '') return true
      return false
    },
    getQuotationRows () {
      if (this.quotationRowsList && this.quotationRowsList.length > 0) {
        const quotationRowsList = cloneDeep(this.quotationRowsList)
        return quotationRowsList.map((row) => {
          row.category_order = parseFloat(row.category_order.toString())
          row.row_order = parseFloat(row.row_order.toString())
          return row
        })
      }
      return []
    },
    isYardQuotation () {
      const yardQuotationRouter = [
        QUOTATION_ROUTES.YARD_QUOTATION_CREATE,
        QUOTATION_ROUTES.YARD_QUOTATION_LIST,
        QUOTATION_ROUTES.YARD_QUOTATION_DETAIL,
        QUOTATION_ROUTES.QUOTATION_CREATE_YARD,
        PROPOSAL_ROUTES.PROPOSAL_CREATE_QUOTATION
      ]
      if (yardQuotationRouter.includes(this.$route.name)) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations({
      setDetail: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.MUTATIONS.setDetail,
      setProposalDetail: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.MUTATIONS.setDetail,
      setOrderByQuotationRows: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.LIST.MUTATIONS.setOrderBy
    }),
    ...mapActions({
      ...QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS,
      getList: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.ACTIONS.getList,
      proposalRetrieve: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.ACTIONS.retrieve,

      // customer invoice address
      customerInvoiceAddressRetrieve: CUSTOMER_INVOICE_ADDRESS_TYPES.GENERIC.quotation.customerinvoiceaddress.DETAIL.ACTIONS.retrieve,
      createCustomerInvoiceAddress: CUSTOMER_INVOICE_ADDRESS_TYPES.GENERIC.quotation.customerinvoiceaddress.DETAIL.ACTIONS.create,
      updateCustomerInvoiceAddress: CUSTOMER_INVOICE_ADDRESS_TYPES.GENERIC.quotation.customerinvoiceaddress.DETAIL.ACTIONS.update,
      cleanAllDataInvoiceAddress: CUSTOMER_INVOICE_ADDRESS_TYPES.GENERIC.quotation.customerinvoiceaddress.DETAIL.ACTIONS.cleanAllData,

      // quotation rows
      getQuotationRowsList: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.LIST.ACTIONS.setFilters,
      createQuotationRowsList: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.DETAIL.ACTIONS.create,
      updateQuotationRowsList: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.DETAIL.ACTIONS.update,
      deleteQuotationRowsList: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.DETAIL.ACTIONS.delete,
      cleanAllDataQuotationRowList: QUOTATION_ROW_TYPES.GENERIC.quotation.quotationrow.LIST.ACTIONS.cleanAllData,

      // quotation installment
      getQuotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.ACTIONS.setFilters,
      createQuotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.DETAIL.ACTIONS.create,
      updateQuotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.DETAIL.ACTIONS.update,
      deleteQuotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.DETAIL.ACTIONS.delete,
      cleanAllDataQuotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.ACTIONS.cleanAllData
    }),
    makeInstallmentFormEditable () {
      this.$set(this, 'installmentFormEditable', true)
    },
    makeFormEditable () {
      this.$set(this, 'formEditable', true)
      this.$set(this, 'installmentFormEditable', true)
    },
    makeFormReadonly () {
      this.$set(this, 'formEditable', false)
      this.$set(this, 'installmentFormEditable', false)
    },
    initFields () {
      if (this.$route.params.proposalId) {
        this.proposalRetrieve({ id: this.$route.params.proposalId }).then(
          () => {
            this.$set(this.form, 'customer', this.proposalDetails.owner)
          }
        )
      } else {
        this.setProposalDetail({})
      }
    },
    getRows () {
      this.setOrderByQuotationRows('category_order.row_order')
      this.getQuotationRowsList({ filter__quotation: this.id, no_page: 'no_page' }).then(() => {
        const quotationRowsList = this.getQuotationRows
        this.$set(this, 'formQuotationRows', quotationRowsList)
        this.$set(this, 'formQuotationRowsInitial', cloneDeep(quotationRowsList))
        this.$set(this, 'isFormQuotationRowsChanged', false)
        this.$set(this, 'invalidServices', false)
        this.$set(this, 'quotationRowsToDeleted', new Set())
        this.$set(this, 'quotationRowsToChanged', new Set())
        this.$set(this, 'resetQuotationRows', !this.resetQuotationRows)
      })
    },
    getInstallments () {
      this.getQuotationInstallmentList({ filter__quotation: this.id, no_page: 'no_page' }).then(() => {
        this.$set(this, 'formQuotationInstallment', cloneDeep(this.quotationInstallmentList))
        this.$set(this, 'formQuotationInstallmentInitial', cloneDeep(this.formQuotationInstallment))
        this.$set(this, 'isFormQuotationInstallmentChanged', false)
        this.$set(this, 'initializeFormInstallment', true)
        this.$set(this, 'quotationInstallmentsToDeleted', new Set())
        this.$set(this, 'quotationInstallmentsToChanged', new Set())
        this.$set(this, 'resetQuotationInstallments', !this.resetQuotationInstallments)
      })
    },
    init () {
      this.cleanErrors()
      this.initFields()
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.makeFormReadonly()
            this.setForm()
            if (this.detail.customer_address_id) {
              this.customerInvoiceAddressRetrieve({ id: this.detail.customer_address_id }).then(() => {
                this.$set(this, 'formInvoiceAddress', cloneDeep(this.customerInvoiceAddress))
                this.$set(this.formInvoiceAddress, 'address_scopes', this.customerInvoiceAddress.address_scopes)
                this.$set(this, 'formInvoiceAddressInitial', cloneDeep(this.formInvoiceAddress))
                this.$set(this, 'isFormInvoiceAddressChanged', false)
              })
            }
            this.getRows()
            this.getInstallments()
          }
        )
      } else {
        this.$set(this, 'initializeFormInstallment', true)
        if (this.isYardQuotation) {
          this.$set(this.form, 'is_yard_quotation', true)
        } else {
          this.$set(this.form, 'is_yard_quotation', false)
        }
        this.makeFormEditable()
      }
      if (!this.form.payment_forms) {
        this.getPaymentFormList({ filter__status: 1 }).then(() => {
          this.paymentFormList.results.forEach((paymentForm) => {
            if (paymentForm.electronic_invoice_code === PAYMENT_FORM_DEFAULT_CODE) {
              const element = {
                id: paymentForm.id,
                name: paymentForm.name,
                description: paymentForm.description,
                status: paymentForm.status
              }
              this.$set(this.form, 'payment_forms', [element])
            }
          })
        })
      }
    },
    setForm () {
      const form = Object.assign({}, cloneDeep(this.detail))
      this.$set(this, 'form', cloneDeep(form))
      this.$set(this, 'formInitial', cloneDeep(form))
      this.$set(this, 'isFormChanged', false)
    },
    closeForm () {
      if (this.isYardQuotation) {
        if (!this.$route.params.proposalId || this.detail.id) {
          this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_LIST })
        } else {
          this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_LIST })
        }
      } else {
        this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_LIST })
      }
    },
    goToList () {
      if (this.isYardQuotation) {
        if (!this.$route.params.proposalId) {
          this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_LIST })
        } else {
          this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_LIST })
        }
      } else {
        this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_LIST })
      }
    },
    updateForm (form) {
      form = { ...this.form, ...form }
      this.$set(this, 'form', form)
      this.$set(this, 'isFormChanged', false)
      // console.log(checkIfEqual(form, this.formInitial))
      if (!checkIfEqual(form, this.formInitial)) {
        this.$set(this, 'isFormChanged', true)
      }
    },
    updateFormInvoiceAddress (formInvoiceAddress) {
      if (!this.checkIfFormIsEmpty(formInvoiceAddress)) {
        formInvoiceAddress = { ...this.formInvoiceAddress, ...formInvoiceAddress }
      }
      this.$set(this, 'formInvoiceAddress', formInvoiceAddress)
      this.$set(this, 'isFormInvoiceAddressChanged', false)
      // console.log(checkIfEqual(formInvoiceAddress, this.formInvoiceAddressInitial))
      if (!checkIfEqual(formInvoiceAddress, this.formInvoiceAddressInitial)) {
        this.$set(this, 'isFormInvoiceAddressChanged', true)
      }
    },
    updateServices (services, invalidServices, quotationRowsToDeleted, quotationRowsToChanged) {
      const formQuotationRows = services
      let totalAmount = 0
      let totalIncome = 0
      services.forEach((product) => {
        if (product.total_with_vat) {
          totalAmount = totalAmount + parseFloat(product.total_with_vat)
          totalIncome = totalIncome + parseFloat(product.total_income)
        }
      })
      this.$set(this, 'totalAmount', totalAmount)
      this.$set(this, 'totalIncome', totalIncome)
      this.$set(this, 'quotationRowsToDeleted', quotationRowsToDeleted)
      this.$set(this, 'quotationRowsToChanged', quotationRowsToChanged)
      this.$set(this, 'formQuotationRows', formQuotationRows)
      this.$set(this, 'invalidServices', invalidServices)
      this.$set(this, 'isFormQuotationRowsChanged', false)
      // console.log(checkIfEqual(formQuotationRows, this.formQuotationRowsInitial))
      if (!checkIfEqual(formQuotationRows, this.formQuotationRowsInitial) || formQuotationRows.length !== this.formQuotationRowsInitial.length) {
        this.$set(this, 'isFormQuotationRowsChanged', true)
      }
    },
    updateInstallments (installments, installmentsToDeleted, installmentsToChanged) {
      const formQuotationInstallment = installments
      this.$set(this, 'formQuotationInstallment', installments)
      this.$set(this, 'quotationInstallmentsToDeleted', installmentsToDeleted)
      this.$set(this, 'quotationInstallmentsToChanged', installmentsToChanged)
      this.$set(this, 'isFormQuotationInstallmentChanged', false)
      // console.log(checkIfEqual(formQuotationInstallment, this.formQuotationInstallmentInitial))
      if (!checkIfEqual(formQuotationInstallment, this.formQuotationInstallmentInitial) || formQuotationInstallment.length !== this.formQuotationInstallmentInitial.length) {
        this.$set(this, 'isFormQuotationInstallmentChanged', true)
      }
    },
    checkIfFormIsEmpty (form) {
      if (form.address_scopes && form.address_scopes.length > 0) return false
      if (form.ssn && form.ssn !== '') return false
      if (form.vat_number && form.vat_number !== '') return false
      if (form.sdi_code && form.sdi_code !== '') return false
      if (form.email && form.email !== '') return false
      if (form.name && form.name !== '') return false
      if (form.number && form.number !== '') return false
      if (form.zip_code && form.zip_code !== '') return false
      if (form.locality && form.locality !== '') return false
      if (form.province && form.province !== '') return false
      if (form.country && form.country !== '') return false
      return true
    },
    getAddressValidator (form, validator) {
      if (!this.checkIfFormIsEmpty(form) || form.id) {
        validator.name = { required }
        validator.country = { required }
        validator.ssn = { required }
        validator.vat_number = { required }
        if (this.checkVatOrSsn) {
          validator.ssn = {}
          validator.vat_number = {}
        }
      } else {
        validator.name = {}
        validator.country = {}
        validator.ssn = {}
        validator.vat_number = {}
        if (this.customerDetail && (this.customerDetail.ssn || this.customerDetail.sdi_code || this.customerDetail.vat_number || this.customerDetail.pec)) {
          validator.ssn = { required }
          validator.vat_number = { required }
        }
      }
      return validator
    },
    cancel () {
      if (!this.id) {
        this.closeForm()
      } else {
        this.setForm()
        this.makeFormReadonly()
        this.$set(this, 'resetForm', !this.resetForm)
        this.$set(this, 'resetInvoiceAddress', !this.resetInvoiceAddress)
        this.$set(this, 'resetQuotationRows', !this.resetQuotationRows)
        this.$set(this, 'resetQuotationInstallments', !this.resetQuotationInstallments)
        this.$set(this, 'quotationRowsToDeleted', new Set())
        this.$set(this, 'quotationRowsToChanged', new Set())
        this.$set(this, 'quotationInstallmentsToDeleted', new Set())
        this.$set(this, 'quotationInstallmentsToChanged', new Set())
      }
    },
    reset () {
      this.cancel()
      this.$set(this, 'isFormChanged', false)
      this.$set(this, 'isFormInvoiceAddressChanged', false)
      this.$set(this, 'isFormQuotationRowsChanged', false)
      this.$set(this, 'isFormQuotationInstallmentChanged', false)
      this.$set(this, 'formInitial', cloneDeep(this.form))
      this.$set(this, 'formInvoiceAddressInitial', cloneDeep(this.formInvoiceAddress))
      this.$set(this, 'formQuotationRowsInitial', cloneDeep(this.formQuotationRows))
      this.$set(this, 'formQuotationInstallmentInitial', cloneDeep(this.formQuotationInstallment))
    },
    resetAfterCreate () {
      this.$set(this, 'isFormChanged', false)
      this.$set(this, 'isFormInvoiceAddressChanged', false)
      this.$set(this, 'isFormQuotationRowsChanged', false)
      this.$set(this, 'isFormQuotationInstallmentChanged', false)
      this.$set(this, 'formInitial', cloneDeep(this.form))
      this.$set(this, 'formInvoiceAddressInitial', cloneDeep(this.formInvoiceAddress))
      this.$set(this, 'formQuotationRowsInitial', cloneDeep(this.formQuotationRows))
      this.$set(this, 'formQuotationInstallmentInitial', cloneDeep(this.formQuotationInstallment))
      this.$set(this, 'resetForm', !this.resetForm)
      this.$set(this, 'resetInvoiceAddress', !this.resetInvoiceAddress)
      this.$set(this, 'resetQuotationRows', !this.resetQuotationRows)
      this.$set(this, 'resetQuotationInstallments', !this.resetQuotationInstallments)
    },
    createOrUpdateForm (form, createName, updateName) {
      if (!form.id) {
        form.quotation = this.detail.id
        return this[createName](form)
      } else {
        return this[updateName](form)
      }
    },
    createOrUpdateArrayForm (list, createName, updateName, deleteName, instancesToDeleted = [], instancesToChanged = []) {
      const promises = []
      if (instancesToDeleted && instancesToDeleted.size > 0) {
        instancesToDeleted.forEach((id) => {
          promises.push(this[deleteName]({ id: id }))
        })
      }
      list.forEach((form) => {
        if (!form.id) {
          form.quotation = this.detail.id
          promises.push(this[createName](form))
        } else {
          if (instancesToChanged.has(form.id)) {
            promises.push(this[updateName](form))
          }
        }
      })
      return promises
    },
    setPaymentForms (formData) {
      if (formData.payment_forms) {
        const list = []
        formData.payment_forms.forEach((row) => list.push(row.id))
        this.$set(formData, 'payment_forms', new Set(list))
      } else {
        this.$set(formData, 'payment_forms', [])
      }
      return formData.payment_forms
    },
    onSubmit () {
      const formData = Object.assign({}, this.form)
      formData.payment_forms = this.setPaymentForms(formData)
      if (formData.supplier_contacts) {
        formData.supplier_contacts = formData.supplier_contacts.map((contact) => {
          if (contact.id) {
            return contact.id
          }
          return contact
        })
      }
      this.setClients(formData)
      const formInvoiceAddressData = Object.assign({}, this.formInvoiceAddress)
      if (formInvoiceAddressData.address_scopes) {
        const list = []
        formInvoiceAddressData.address_scopes.forEach((row) => list.push(row.id))
        this.$set(formInvoiceAddressData, 'address_scopes', new Set(list))
      } else {
        this.$set(formInvoiceAddressData, 'address_scopes', [])
      }
      const formQuotationRowsData = Object.assign([], this.formQuotationRows)
      let formQuotationInstallmentData = Object.assign([], this.formQuotationInstallment)
      if (this.$route.params.proposalId) {
        formData.proposal = this.$route.params.proposalId
        delete formInvoiceAddressData.id
      }
      if (formQuotationInstallmentData.length > 0) {
        formQuotationInstallmentData = formQuotationInstallmentData.map((installment, index) => {
          const quotationInstallment = { ...installment }
          if (quotationInstallment.amount) {
            quotationInstallment.amount = parseFloat(quotationInstallment.amount.toString())
          }
          if (installment.amount_perc && installment.amount_perc !== '') {
            quotationInstallment.amount = null
          } else {
            quotationInstallment.amount_perc = null
          }
          quotationInstallment.ordering = index
          return quotationInstallment
        })
      }
      const promises = []
      if (!this.id) {
        this.create(formData).then(() => {
          if (!this.checkIfFormIsEmpty(formInvoiceAddressData)) {
            formInvoiceAddressData.nominative = formData.customer_name
            promises.push(this.createOrUpdateForm(
              formInvoiceAddressData,
              'createCustomerInvoiceAddress',
              'updateCustomerInvoiceAddress'
            ))
          }
          promises.push(...this.createOrUpdateArrayForm(
            formQuotationRowsData,
            'createQuotationRowsList',
            'updateQuotationRowsList'
          ))
          promises.push(...this.createOrUpdateArrayForm(
            formQuotationInstallmentData,
            'createQuotationInstallmentList',
            'updateQuotationInstallmentList'
          ))
          Promise.all(promises).then(() => {
            this.getList()
            this.resetAfterCreate()
            if (this.isYardQuotation) {
              this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_DETAIL, params: { id: this.detail.id } })
            } else {
              this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_DETAIL, params: { id: this.detail.id } })
            }
          }).catch(() => {
            if (this.isYardQuotation) {
              this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_DETAIL, params: { id: this.detail.id } })
            } else {
              this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_DETAIL, params: { id: this.detail.id } })
            }
          })
        })
      } else {
        if (this.isFormChanged) {
          if (formData.signed_quotation) {
            delete formData.signed_quotation
          }
          promises.push(this.update(formData))
        }
        if (this.isFormInvoiceAddressChanged) {
          formInvoiceAddressData.nominative = formData.customer_name
          promises.push(this.createOrUpdateForm(
            formInvoiceAddressData,
            'createCustomerInvoiceAddress',
            'updateCustomerInvoiceAddress'
          ))
        }
        if (this.isFormQuotationRowsChanged) {
          promises.push(...this.createOrUpdateArrayForm(
            formQuotationRowsData,
            'createQuotationRowsList',
            'updateQuotationRowsList',
            'deleteQuotationRowsList',
            this.quotationRowsToDeleted,
            this.quotationRowsToChanged
          ))
        }
        if (this.isFormQuotationInstallmentChanged) {
          promises.push(...this.createOrUpdateArrayForm(
            formQuotationInstallmentData,
            'createQuotationInstallmentList',
            'updateQuotationInstallmentList',
            'deleteQuotationInstallmentList',
            this.quotationInstallmentsToDeleted,
            this.quotationInstallmentsToChanged
          ))
        }
        Promise.all(promises).then(() => {
          this.getList()
          this.init()
        })
      }
    },
    onSubmitInstallmentForm () {
      let formQuotationInstallmentData = Object.assign([], this.formQuotationInstallment)
      if (formQuotationInstallmentData.length > 0) {
        formQuotationInstallmentData = formQuotationInstallmentData.map((installment) => {
          const quotationInstallment = { ...installment }
          quotationInstallment.amount = parseFloat(quotationInstallment.amount.toString())
          if (installment.amount_perc && installment.amount_perc !== '') {
            quotationInstallment.amount = null
          } else {
            quotationInstallment.amount_perc = null
          }
          return quotationInstallment
        })
      }
      const promises = []
      if (this.isFormQuotationInstallmentChanged) {
        promises.push(...this.createOrUpdateArrayForm(
          formQuotationInstallmentData,
          'createQuotationInstallmentList',
          'updateQuotationInstallmentList',
          'deleteQuotationInstallmentList',
          this.quotationInstallmentsToDeleted,
          this.quotationInstallmentsToChanged
        ))
      }
      Promise.all(promises).then(() => {
        this.init()
      })
    },
    createOldQuotation (evt) {
      this.$set(this, 'isCreateOldQuotation', evt)
    }
  },
  validations () {
    const validator = {
      form: {
        clients: { },
        date_q: { required },
        date_firm_for: { required },
        customer: { required },
        supplier_contacts: {},
        payment_forms: {},
        description: { required },
        quotation_extra_module: {},
        extra_module_title: {},
        extra_content: {}
      },
      formInvoiceAddress: {
        ssn: {},
        vat_number: {},
        sdi_code: {},
        email: {},
        address_type: {},
        name: {},
        number: { maxLength: maxLength(20) },
        zip_code: { maxLength: maxLength(20) },
        locality: {},
        province: { maxLength: maxLength(2) },
        country: {}
      },
      formQuotationRows: {
        $each: {
          products: {
            $each: {
              product: { required },
              product_name: { required },
              long_description: {},
              quantity: { required },
              price: { required },
              vat: { required }
            }
          }
        }
      },
      formQuotationInstallment: {
        $each: {
          name: { required },
          type: {},
          amount: { },
          amount_perc: {},
          expiration_date: {},
          date_invoice_issue: {},
          vat: { }
        }
      }
    }
    if (this.formQuotationInstallment.days_end_month) {
      validator.formQuotationInstallment.end_month = { required }
    }
    if (this.formQuotationInstallment.name) {
      validator.formQuotationInstallment.vat = { required }
    }
    if (this.form.quotation_extra_module) {
      validator.form.extra_module_title = { required }
      validator.form.extra_content = { required }
    }

    validator.formInvoiceAddress = this.getAddressValidator(this.formInvoiceAddress, validator.formInvoiceAddress)
    if (this.isYardQuotation) {
      validator.form.clients = { required }
    } else {
      validator.form.clients = { }
    }
    return validator
  }
}
</script>
