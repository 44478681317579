<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/store'

export default {
  name: 'CustomerMixin',
  created () {
    if (!this.customerList || (this.customerList && this.customerList.length === 0)) {
      this.setCustomerList({})
    }
  },
  computed: {
    ...mapGetters({
      customerList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list,
      customerDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail
    }),
    customerListOptions () {
      const options = []
      if (this.customerList && this.customerList.results && this.customerList.results.length > 0) {
        this.customerList.results.forEach((customer) => {
          options.push({
            value: customer.id,
            text: customer.typology_name ? `${customer.name} (${customer.typology_name})` : customer.name,
            typology: customer.typology
          })
        })
      }
      return options
    },
    clientOptions () {
      const options = []
      if (this.customerList && this.customerList.results && this.customerList.results.length > 0) {
        this.customerList.results.forEach((customer) => {
          options.push({
            value: customer.id,
            text: customer.typology_name ? `${customer.name} (${customer.typology_name})` : customer.name
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setCustomerList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList,
      setCustomerDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      getCustomerList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters,
      getCustomerDetail: ORGANIZATION_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.retrieve
    }),
    onSearchCustomer (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getCustomerList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setCustomerList({})
      }
    },
    selectCustomer (item) {
      this.$set(this.form, 'customer', item.value)
      this.$set(this.form, 'customer_name', item.text)
      this.$emit('change-owner')
    },
    clearCustomer () {
      this.$set(this.form, 'customer', null)
      this.$set(this.form, 'customer_name', null)
    },
    onSearchYardClient (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getCustomerList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setCustomerList({})
      }
    },
    selectYardClient (item) {
      this.$set(this.form, 'client', item.value)
      this.$set(this.form, 'client_name', item.text)
    },
    clearYardClient () {
      this.$set(this.form, 'client', null)
      this.$set(this.form, 'client_name', null)
    }
  }
}
</script>
