<template>
  <div class="btn-actions">
    <slot name="other-buttons-top"></slot>
    <!--button instance detail page-->
    <b-btn
      v-if="showDetailButton"
      variant="outline-primary"
      size="sm"
      :title="`${$t('Detail')} ${$t(instanceName)}`"
      @click="$emit('open-detail-form', id)"
    >
      <i
        v-if="showDetailButton"
        :title="`${$t('Detail')} ${$t(instanceName)}`"
        class="fas fa-search"
      />
    </b-btn>
    <b-btn
      v-if="showEditButton"
      variant="outline-primary"
      size="sm"
      :title="`${$t('Edit')} ${$t(instanceName)}`"
      @click="$emit('open-edit-form', id)"
    >
      <i
        v-if="showEditButton"
        :title="`${$t('Edit')} ${$t(instanceName)}`"
        class="far fa-edit"
      />
    </b-btn>
    <slot name="other-buttons-bottom"></slot>
  </div>
</template>

<script>
export default {
  name: 'buttons-actions-thux',
  props: {
    instanceName: { required: true },

    // SHOW AND EDIT
    showEditButton: { default: false },
    showDetailButton: { default: false },
    id: { required: true }
  }
}
</script>
