<template>
  <div class="panel-services-manager">
    <panel :title="'Services' | translate" noButton="true" panel-class="panel-services">
      <draggable
        :scroll-sensitivity="250"
        :fallback-tolerance="1"
        :force-fallback="true"
        class="col-12 p-0"
        v-model="services"
        @change="checkMove"
        :disabled="!formEditableUpdate"
      >
        <panel
          v-for="(service, index) in services"
          :key="service.typology.id"
          :title="service.typology.name"
          class="mb-3"
          :do-collapse="true"
          :buttons="[]"
          panel-class="panel-light"
        >
          <template slot="header-actions">
            <b-btn :disabled="!formEditableUpdate" variant="outline-dark" @click="removeTypology(index)">
              <i class="fas fa-times"></i>
              {{ 'Remove category' | translate }}
            </b-btn>
          </template>
          <service-products-manager
            :form-editable-update="formEditableUpdate"
            :service="service"
            @update-products="updateTypologyProducts($event, index)"
          />
        </panel>
      </draggable>
    </panel>
    <b-form class="panel-body">
      <b-form-row class="align-items-center">
        <div class="col-xl-1" v-if="services && services.length > 0">
          <b-btn
            size="lg"
            variant="primary"
            class="w-100"
            @click="showSelectForm = !showSelectForm"
            :disabled="!formEditableUpdate"
          >
            <i class="fas fa-plus mr-1"></i>
            {{ 'Typology' | translate }}
          </b-btn>
        </div>
        <service-typology-select
          class="col-xl-11"
          v-show="services && services.length === 0 || showSelectForm"
          :typologies-selected="services"
          @submit-typology="onSubmitTypology"
          :disabled="!formEditableUpdate"
        />
      </b-form-row>
    </b-form>
  </div>
</template>

<script>

import ServiceTypologySelect from './ServiceTypologySelect'
import ServiceProductsManager from './ServiceProductsManager'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'
import draggable from 'vuedraggable'
import VueDraggableMixin from '../../../../../components/order/VueDraggableMixin'

export default {
  name: 'ServicesComponent',
  mixins: [VueDraggableMixin],
  props: {
    quotationRows: {},
    formEditableUpdate: {},
    resetQuotationRows: {}
  },
  components: { ServiceProductsManager, ServiceTypologySelect, draggable },
  watch: {
    services: {
      handler () {
        let invalid
        const productsIsEmpty = this.services.some((service) => {
          if (service.products.length === 0) return true
          return service.products.some((product) => { return Object.keys(product).length === 0 })
        })
        if (productsIsEmpty) {
          invalid = true
        } else {
          invalid = this.$v.services.$invalid
        }
        const products = []
        this.services.forEach((service) => {
          if (service.products) {
            products.push(...service.products.map((product) => {
              product.category_order = service.category_order
              return product
            }))
          }
        })
        this.$emit('update-services', products, invalid, this.productsToDeleted, this.productsToChanged)
      },
      deep: true
    },
    quotationRows () {
      this.updateQuotationRows()
    },
    resetQuotationRows () {
      this.updateQuotationRows()
    }
  },
  data () {
    return {
      services: [],
      productsToDeleted: new Set(),
      productsToChanged: new Set(),
      showSelectForm: false,
      listName: 'services',
      orderFieldName: 'category_order'
    }
  },
  computed: {
    getServicesIndex () {
      if (this.services && this.services.length > 0) {
        return this.services.map(service => service.category_order)
      }
      return []
    }
  },
  methods: {
    updateQuotationRows () {
      const services = []
      if (this.quotationRows.length > 0) {
        this.quotationRows.forEach(element => {
          const index = services.findIndex((el) => { return el.typology.id === element.typology_id })
          if (index === -1) {
            const service = {}
            service.typology = {
              id: element.typology_id,
              name: element.typology_name,
              is_formation: element.typology_type_of_sale === 'UN'
            }
            service.id = element.typology_id
            service.name = element.typology_name
            service.category_order = parseFloat(Object.assign({}, element).category_order)
            service.products = []
            service.products.push(Object.assign({}, element))
            services.push(service)
          } else {
            services[index].products.push(Object.assign({}, element))
          }
        })
        this.$set(this, 'services', cloneDeep(services))
      }
    },
    updateTypologyProducts ({ products, productToDeleted, productToChanged }, index) {
      if (productToDeleted) {
        this.productsToDeleted.add(productToDeleted)
        this.productsToChanged.delete(productToDeleted)
      }
      if (productToChanged) {
        this.productsToChanged.add(productToChanged)
      }
      this.$set(this.services[index], 'products', products)
    },
    removeTypology (index) {
      if (this.services[index] && this.services[index].products && this.services[index].products.length > 0) {
        this.services[index].products.forEach((product) => {
          if (product.id) {
            this.productsToDeleted.add(product.id)
          }
        })
      }
      this.services.splice(index, 1)
    },
    onSubmitTypology (item) {
      let categoryOrder = 1
      if (this.services[this.services.length - 1]) {
        categoryOrder = parseInt(this.services[this.services.length - 1].category_order) + 1
      }
      this.$set(this, 'showSelectForm', false)
      this.services.push({
        name: item.name,
        id: item.id,
        typology: item,
        category_order: categoryOrder,
        products: []
      })
    }
  },
  validations: {
    services: {
      $each: {
        products: {
          $each: {
            product: { required },
            product_name: { required },
            long_description: {},
            quantity: { required },
            price: { required },
            vat: { required }
          }
        }
      }
    }
  }
}
</script>
