<template>
  <panel :title="'Billing data' | translate" :no-button="true">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-multiselect
          :external-errors="validatorPaymentForms"
          :label-form="'Payment forms' | translate"
          class-form="col-12 mb-3 mt-2"
          labelColsSm="2"
          labelColsLg="2"
          :disabled="!formEditableUpdate"
          label-search="name"
          label-form-class="col-half-width"
          :value="paymentForms"
          :placeholder="
            $t('Select a {instance}', { instance: $t('payment form') })
          "
          :options="paymentFormListOptions"
          @search="onSearchPaymentForm"
          @remove="removePaymentForm"
          @select="selectPaymentForm"
          @clear="clearPaymentForm"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-input
          :validator="$v.form.ssn"
          :external-errors="errors['ssn']"
          :label-form="'SSN' | translate"
          class-form="col-lg-6 mb-2"
          labelColsSm="3"
          labelColsLg="3"
          type="text"
          :value="$v.form.ssn.$model"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'ssn', $event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.vat_number"
          :external-errors="errors['vat_number']"
          :label-form="'VAT number' | translate"
          class-form="col-lg-6 mb-2"
          labelColsSm="3"
          labelColsLg="3"
          type="text"
          :value="$v.form.vat_number.$model"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'vat_number', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-input v-if="owner_type !== 'PA'"
          :validator="$v.form.sdi_code"
          :external-errors="errors['sdi_code']"
          :label-form="'SDI' | translate"
          class-form="col-lg-6 mb-2"
          labelColsSm="3"
          labelColsLg="3"
          type="text"
          :value="$v.form.sdi_code.$model"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'sdi_code', $event)"
        />
        <form-thux-horizontal-input v-if="owner_type === 'PA'"
          :validator="$v.form.sdi_code"
          :external-errors="errors['sdi_code']"
          :label-form="'Unique Office Code' | translate"
          class-form="col-lg-6 mb-2"
          labelColsSm="3"
          labelColsLg="3"
          type="text"
          :value="$v.form.sdi_code.$model"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'sdi_code', $event)"
        />
        <form-thux-horizontal-input
          :validator="$v.form.email"
          :external-errors="errors['email']"
          :label-form="'Email' | translate"
          class-form="col-lg-6 mb-2"
          labelColsSm="3"
          labelColsLg="3"
          type="text"
          :value="$v.form.email.$model"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'email', $event)"
        />
      </b-form-row>
    </b-form>
    <hr>
    <b-form class="mb-3" v-if="owner" >
      <b-button class="mr-2" :disabled="!formEditableUpdate || !owner" size="md" variant="primary" @click.prevent="openSelectAddressModal()">
        <i class="fas fa-map-pin mr-1"></i>
        {{ 'Select address' | translate }}
      </b-button>
    </b-form>
    <address-component
      :id="id"
      :detail="detailOwnerAddress"
      :errors="addressErrors"
      :form-editable-update="formEditableUpdate"
      :other-condition="getOtherCondition"
      :reset-address="resetInvoiceAddress"
      :selectedAddress="selectedAddress"
      :required-edit="false"
      @reset="$emit('reset')"
      @set-form="setInvoiceAddress"
    />
    <select-address-modal v-if="showSelectAddressModal" :orgId="owner" v-on:close-select-address="closeSelectAddressModal"></select-address-modal>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CustomerMixin from '../../mixins/CustomerMixin'
import { mapActions, mapGetters } from 'vuex'
import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import SelectAddressModal from '../../../../proposal/owner-proposal-address/SelectAddressModal'
import AddressComponent from '../../../../proposal/components/AddressComponent'
import { TYPES as OWNER_PROPOSAL_TYPES } from '../../../../proposal/owner-proposal-address/store'
import { TYPES as CUSTOMER_INVOICE_ADDRESS_TYPES } from '../../../customer-invoice-address/store'
import { cloneDeep } from 'lodash'
import PaymentFormsMixin from '../../mixins/PaymentFormsMixin'

export default {
  name: 'BillingForm',
  mixins: [ThuxDetailPanelMixin, CustomerMixin, PaymentFormsMixin],
  props: {
    validatorPaymentForms: {},
    formEditableUpdate: {},
    changeOwner: {},
    owner: {},
    owner_type: {},
    errors: { required: true },
    proposal: {},
    resetInvoiceAddress: {}
  },
  components: {
    AddressComponent,
    SelectAddressModal
  },
  watch: {
    changeOwner () {
      if (this.owner) {
        this.getCustomerDetail({ id: this.owner }).then(
          () => {
            if (!this.proposal || !this.proposal.ssn) {
              this.$set(this.form, 'ssn', this.customerDetail.ssn)
            }
            if (!this.proposal || !this.proposal.vat_number) {
              this.$set(this.form, 'vat_number', this.customerDetail.vat_number)
            }
            this.$set(this.form, 'sdi_code', this.customerDetail.sdi_code)
            if (!this.proposal || !this.proposal.owner_email) {
              if (this.customerDetail.pec) {
                this.$set(this.form, 'email', this.customerDetail.pec)
              } else if (this.customerDetail.default_email) {
                this.$set(this.form, 'email', this.customerDetail.default_email)
              }
            }
          }
        )
      } else {
        this.setCustomerDetail({})
      }
    },
    form: {
      handler () {
        this.$emit('update-form-invoice-address', this.form)
      },
      deep: true
    },
    proposal () {
      this.init()
    },
    resetInvoiceAddress () {
      this.init()
    },
    id () {
      this.init()
    }
  },
  data () {
    return {
      form: {},
      addressForm: {},
      showSelectAddressModal: false,
      selectedAddress: {},
      detailOwnerAddress: {},
      addressErrors: {}
    }
  },
  created () {
    this.setCustomerDetail({})
  },
  computed: {
    ...mapGetters({
      detailOwnerProposalAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.GETTERS.detail,
      customerInvoiceAddress: CUSTOMER_INVOICE_ADDRESS_TYPES.GENERIC.quotation.customerinvoiceaddress.DETAIL.GETTERS.detail
    }),
    getOtherCondition () {
      if (this.form.ssn && this.form.ssn !== '') return true
      if (this.form.vat_number && this.form.vat_number !== '') return true
      if (this.form.sdi_code && this.form.sdi_code !== '') return true
      if (this.form.email && this.form.email !== '') return true
      return false
    },
    checkSsn () {
      if (this.form.vat_number && this.form.vat_number !== '') return false
      if (this.form.ssn && this.form.ssn !== '') return true
      return false
    },
    checkVat () {
      if (this.form.ssn && this.form.ssn !== '') return false
      if (this.form.vat_number && this.form.vat_number !== '') return true
      return false
    }
  },
  methods: {
    ...mapActions({
      retrieveOwnerProposalAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.ACTIONS.retrieve
    }),
    init () {
      this.$set(this.form, 'ssn', this.proposal.owner_ssn)
      this.$set(this.form, 'vat_number', this.proposal.owner_vat_number)
      this.$set(this.form, 'email', this.proposal.owner_email)
      if (this.proposal && this.proposal.number) {
        this.getCustomerDetail({ id: this.proposal.owner }).then(() => {
          this.$set(this.form, 'sdi_code', this.customerDetail.sdi_code)
          if (!this.proposal.owner_email) {
            if (this.customerDetail.pec) {
              this.$set(this.form, 'email', this.customerDetail.pec)
            } else if (this.customerDetail.default_email) {
              this.$set(this.form, 'email', this.customerDetail.default_email)
            }
          }
        })
        if (this.proposal.owner_prop_address_id) {
          this.retrieveOwnerProposalAddress({ id: this.proposal.owner_prop_address_id }).then(
            () => {
              this.$set(this, 'detailOwnerAddress', cloneDeep(this.detailOwnerProposalAddress))
            }
          )
        }
      } else {
        const form = { ...this.form, ...this.customerInvoiceAddress }
        this.$set(this, 'form', form)
        this.$set(this, 'detailOwnerAddress', cloneDeep(this.customerInvoiceAddress))
      }
    },
    setInvoiceAddress (invoice) {
      const form = { ...this.form, ...invoice }
      this.$set(this, 'form', form)
    },
    openSelectAddressModal () {
      this.showSelectAddressModal = true
    },
    closeSelectAddressModal (evtData) {
      if (evtData) {
        this.$set(this.form, 'owner_address', evtData.id)
        this.selectedAddress = evtData
      }
      this.showSelectAddressModal = false
    },
    checkIfFormIsEmpty () {
      if (this.form.address_scopes && this.form.address_scopes.length > 0) return false
      if (this.form.ssn && this.form.ssn !== '') return false
      if (this.form.address_type && this.form.address_type !== '') return false
      if (this.form.vat_number && this.form.vat_number !== '') return false
      if (this.form.sdi_code && this.form.sdi_code !== '') return false
      if (this.form.email && this.form.email !== '') return false
      if (this.form.name && this.form.name !== '') return false
      if (this.form.number && this.form.number !== '') return false
      if (this.form.zip_code && this.form.zip_code !== '') return false
      if (this.form.locality && this.form.locality !== '') return false
      if (this.form.province && this.form.province !== '') return false
      if (this.form.country && this.form.country !== '') return false
      return true
    },
    getAddressValidator (validator) {
      if (!this.checkIfFormIsEmpty() || this.form.id) {
        validator.name = { required }
        validator.country = { required }
        if (this.checkVat) {
          validator.ssn = {}
        }
        if (this.checkSsn) {
          validator.vat_number = {}
        }
      } else {
        validator.name = {}
        validator.country = {}
        validator.ssn = {}
        validator.vat_number = {}
        if (this.customerDetail && (this.customerDetail.ssn || this.customerDetail.sdi_code || this.customerDetail.vat_number || this.customerDetail.pec)) {
          validator.ssn = { required }
          validator.vat_number = { required }
        }
      }
      return validator
    }
  },
  validations () {
    const form = {
      ssn: { required },
      vat_number: { required },
      sdi_code: {},
      email: {}
    }
    return { form: this.getAddressValidator(form) }
  }
}
</script>
