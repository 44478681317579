<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.product"
        :label-form="'Product' | translate"
        class-form="input-row-col mb-2 mt-2"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        :value="form.product ? { id: $v.form.product.$model, name: $v.form.product_name.$model } : null"
        :options="productListOptions"
        :placeholder="$t('Type a product')"
        :placeholder-field="form.product_name"
        @search="onSearchProduct"
        @select="selectProduct"
        @clear="clearProduct"
      />
      <form-thux-horizontal-input
        v-if="typology.is_formation"
        :validator="$v.form.quantity"
        :label-form="'Quantity' | translate"
        class-form="input-row-col input-col-sm mb-2 mt-2"
        type="number"
        :min="0"
        :value="$v.form.quantity.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'quantity', $event)"
      />
      <form-thux-horizontal-input
        v-if="typology.is_formation"
        :validator="$v.form.price"
        :label-form="'Unit price' | translate"
        class-form="input-row-col input-col-sm mb-2 mt-2"
        type="number"
        :min="0"
        :value="$v.form.price.$model"
        labelColsSm="0"
        labelColsLg="0"
        placeholder="€"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'price', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.price"
        :label-form="'Rateable' | translate"
        class-form="input-row-col input-col-sm mb-2 mt-2"
        :type="!typology.is_formation ? 'number' : 'text'"
        :min="0"
        :value="typology.is_formation ? getRateable : form.price"
        labelColsSm="0"
        labelColsLg="0"
        placeholder="€"
        :disabled="!!typology.is_formation || !formEditableUpdate"
        @change="!typology.is_formation ? $set(form, 'price', $event) : undefined"
      />
      <form-thux-horizontal-autocomplete
        :validator="$v.form.vat"
        :label-form="'VAT' | translate"
        class-form="input-row-col input-col-sm mb-2 mt-2"
        :disabled="!formEditableUpdate"
        :customize-option="true"
        labelColsSm="0"
        labelColsLg="0"
        label-search="name"
        :value="form.vat ? { value: $v.form.vat.$model, name: $v.form.vat_name.$model } : null"
        :options="vatListOptions"
        :placeholder="$t('VAT')"
        :placeholder-field="form.vat_name ? form.vat_name.toString() : null"
        @select="selectVat"
        @clear="clearVat"
      />
      <form-thux-horizontal-input
        :validator="$v.form.amount"
        :label-form="'Amount' | translate"
        class-form="input-row-col input-col-sm mb-2 mt-2"
        :min="0"
        :value="getAmount ? getAmount : null"
        placeholder="€"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="true"
      />
      <b-btn-group class="col-xl-1 btn-row-actions my-2">
        <b-btn
          v-if="showAddButton"
          class="mr-1"
          variant="primary"
          :disabled="$v.form.$invalid || !formEditableUpdate"
          @click="addProduct
        ">
          <i class="fas fa-plus"></i>
        </b-btn>
        <b-btn class="mr-1" variant="outline-primary" @click="removeProduct" :disabled="!formEditableUpdate">
          <i :class="index === 0 && productsLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
        </b-btn>
      </b-btn-group>
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-ckeditor
        :validator="$v.form.long_description"
        :label-form="'Description' | translate"
        class-form="col-12 mb-2 mt-2 ckeditor-autosize"
        :value="$v.form.long_description.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate"
        :classes="'editor-sm'"
        @change="$set(form, 'long_description', $event)"
      />
    </b-form-row>
  </b-form>
</template>

<script>
import { vue } from '../../../../../main'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import { TYPES as PRODUCT_TYPES } from '../../../../product/product/store'
import VatMixin from '../../../../product/product/mixins/VatMixin'

export default {
  name: 'ServiceProductForm',
  mixins: [ThuxDetailPanelMixin, VatMixin],
  props: {
    typology: { required: true },
    formUpdate: {},
    index: {},
    productsLength: {},
    showAddButton: {},
    formEditableUpdate: {}
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {}
    }
  },
  created () {
    this.setProductList({})
    this.init()
  },
  computed: {
    ...mapGetters({
      ...PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS
    }),
    productListOptions () {
      const options = []
      const productList = this.list && this.list.results ? this.list.results : []
      productList.forEach((product) => {
        const option = {
          id: product.id,
          name: product.name,
          typology: this.typology.id,
          full_description: product.full_description,
          base_price: product.base_price,
          status: product.status
        }
        if (option.status === 0) {
          option.$isDisabled = true
        }
        options.push(option)
      })
      return options
    },
    getRateable () {
      const quantity = this.form.quantity ? this.form.quantity : parseFloat('1')
      const unitPrice = this.form.price ? this.form.price : 0
      if (quantity && unitPrice) {
        return (parseInt(quantity) * parseFloat(unitPrice)).toFixed(2)
      }
      return null
    },
    getAmount () {
      const vat = this.form.vat_aliquot_perc ? parseFloat(this.form.vat_aliquot_perc.toString()) : 0
      if (vat && this.getRateable) {
        const rateable = parseFloat(this.getRateable)
        const vatPrice = (parseInt(vat.toString()) * rateable) / 100
        return parseFloat((rateable + vatPrice).toString()).toFixed(2)
      }
      return null
    }
  },
  methods: {
    ...mapMutations({
      setProductList: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS
    }),
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
      }
      if (!this.typology.is_formation) {
        this.$set(this.form, 'quantity', this.formUpdate.quantity ? this.formUpdate.quantity : 1)
      }
      this.$watch('form', () => {
        this.$set(this.form, 'total_with_vat', parseFloat(this.getAmount))
        this.$emit('edit-product', this.form)
      }, { deep: true })
    },
    onSearchProduct (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.filter__categories__in = this.typology.id
        filters.order_by = '-status.-date_create'
        this.setFilters(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectProduct (item) {
      this.$set(this.form, 'product', item.id)
      this.$set(this.form, 'product_name', item.name)
      this.$set(this.form, 'long_description', item.full_description)
      this.$set(this.form, 'price', item.base_price)
    },
    clearProduct () {
      this.$set(this.form, 'product', null)
      this.$set(this.form, 'product_name', null)
      this.$set(this.form, 'long_description', null)
      this.$set(this.form, 'price', null)
    },
    addProduct () {
      if (!this.$v.form.$invalid) {
        this.$emit('add-product', this.$v.form.$model)
        if (!this.typology.is_formation && !this.formUpdate && !this.formUpdate.id) {
          this.$set(this.form, 'quantity', 1)
        }
      }
    },
    removeProduct () {
      if (this.index > 0 || this.productsLength > 1) {
        this.$emit('remove-product', this.form)
      } else {
        this.$set(this, 'form', {})
        if (!this.typology.is_formation && !this.formUpdate && !this.formUpdate.id) {
          this.$set(this.form, 'quantity', 1)
        }
      }
    }
  },
  validations () {
    const form = {
      product: { required },
      product_name: { required },
      long_description: {},
      quantity: { required },
      price: { required },
      vat: { required },
      vat_aliquot_perc: { required },
      vat_name: { required }
    }
    return { form: form }
  }
}
</script>
<style>
.ckeditor-autosize .ck-editor__editable {
  min-height: 150px;
  max-height: unset;
}
</style>
