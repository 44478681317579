<template>
  <b-modal
      :no-enforce-focus = 'true'
      ref="modalSelectExtraModule"
      id="modalSelectExtraModule"
      size='lg'
      @hidden="modalSelectExtraModuleOnHidden"
      class="modal-top">
      <div slot="modal-title">
        {{ 'Select extra module' |translate}}
      </div>

      <thux-table
        :instance="instance"
        :instance-name="instanceName"
        :order-by-dict="orderByDict"
        :goBack="goBack"
        :list="extraModuleList"
        :local-selected-list="[]"
        :selected-list="[]"
        :fields-column="[]"
        :fieldName="fieldName"
        :fields="fields"
        :show-checks="false"
        :showDetailButton="false"
      >
        <template slot="other-buttons-actions-bottom" slot-scope="row">
          <b-btn
            variant="outline-primary"
            :title="'Select' | translate"
            @click.prevent="$emit('close-select-extra-module', row.item)"
          >
            <i class="fas fa-check"></i>
          </b-btn>
        </template>
      </thux-table>
       <div slot="modal-footer" class="w-100">
       </div>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as EXTRA_MODULE_TYPES } from '../../../quotation-extra-module/store'
import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'

export default {
  name: 'SelectExtraModuleModal',
  mixins: [ThuxTableMixin],
  data () {
    return {
      fields: [
        {
          key: 'code',
          sortable: false,
          label: this.$t('Code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'code'
        },
        {
          key: 'name',
          sortable: false,
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'name'
        },
        {
          key: 'action',
          sortable: false,
          label: '',
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ]
    }
  },
  mounted () {
    this.openModalSelectAddress()
  },
  computed: {
    ...mapGetters({
      extraModuleList: EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.GETTERS.list
    })
  },
  methods: {
    ...mapMutations({
      setExtraModuleList: EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getExtraModuleList: EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.ACTIONS.setFilters
    }),
    openModalSelectAddress () {
      this.$refs.modalSelectExtraModule.show()
    },
    modalSelectExtraModuleOnHidden (evt) {
      this.$refs.modalSelectExtraModule.hide()
      this.$emit('close-select-extra-module')
    }
  },
  created () {
    this.setExtraModuleList([])
    const filters = { filter__status: 1, no_page: 'no_page' }
    this.getExtraModuleList(filters)
  }
}
</script>
