<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as QUOTATION_INSTALLMENT_TYPES } from '../../quotation-installment/store'

export default {
  name: 'TypologyChoicesMixin',
  created () {
    if (!this.typologyChoicesList || (this.typologyChoicesList && this.typologyChoicesList.length === 0)) {
      this.getTypologyChoices()
    }
  },
  computed: {
    ...mapGetters({
      typologyChoicesList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.GETTERS.typologyChoices
    }),
    typologyChoicesListOptions () {
      const options = []
      if (this.typologyChoicesList && this.typologyChoicesList.length > 0) {
        this.typologyChoicesList.forEach((typology) => {
          options.push({
            id: typology[0],
            name: typology[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getTypologyChoices: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.ACTIONS.getTypologychoices
    }),
    selectTypologyChoices (item) {
      this.$set(this.form, 'type', item.id)
      this.$set(this.form, 'get_type_display', item.name)
    },
    clearTypologyChoices () {
      this.$set(this.form, 'type', null)
      this.$set(this.form, 'get_type_display', null)
    }
  }
}
</script>
