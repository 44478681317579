<script>
import { cloneDeep } from 'lodash'
import { INDEX_ORDER_MAX_DECIMAL_PLACES } from '../../const'

export default {
  name: 'VueDraggableMixin',
  data () {
    return {
      listName: '',
      orderFieldName: ''
    }
  },
  methods: {
    printLog (order, prevOrder, nextOrder, roundDown, roundUp) {
      console.log(`order ${order}`)
      console.log(`prevOrder ${prevOrder}`)
      console.log(`nextOrder ${nextOrder}`)
      console.log(`roundDown ${roundDown}`)
      console.log(`roundUp ${roundUp}`)
      console.log('\n')
      console.log('----------------')
    },
    getDecimalPlacesCount (indexOrder) {
      const numStr = indexOrder.toString()
      if (numStr.includes('.')) {
        return numStr.split('.')[1].length
      }
      return 0
    },
    roundUp (num, precision) {
      precision = Math.pow(10, precision)
      return Math.ceil(num * precision) / precision
    },
    roundDown (num, precision) {
      precision = Math.pow(10, precision)
      return Math.floor(num * precision) / precision
    },
    getOrder (order, prevOrder, nextOrder) {
      const decimalPlaces = this.getDecimalPlacesCount(order)
      const roundDown = this.roundDown(order, decimalPlaces - 1)
      const roundUp = this.roundUp(order, decimalPlaces - 1)

      if (roundDown > prevOrder && nextOrder && roundDown < nextOrder) {
        order = roundDown
      } else if (roundUp > prevOrder && nextOrder && roundUp < nextOrder) {
        order = roundUp
      }
      return order
    },
    resetOrdering () {
      return this[this.listName].map((instance, index) => {
        instance[this.orderFieldName] = index + 1
        return instance
      })
    },
    getOrderAndPrevOrderAndNextOrder (list, newIndex) {
      let prevOrder
      let nextOrder
      let order
      const maxIndex = list.length - 1
      if (newIndex === 0) {
        prevOrder = 0
        nextOrder = parseFloat(list[1][this.orderFieldName])
        order = (prevOrder + nextOrder) / 2
      } else if (newIndex === maxIndex) {
        prevOrder = parseFloat(list[maxIndex - 1][this.orderFieldName])
        nextOrder = undefined
        order = parseInt(prevOrder) + 1
      } else {
        prevOrder = parseFloat(list[newIndex - 1][this.orderFieldName])
        nextOrder = parseFloat(list[newIndex + 1][this.orderFieldName])
        order = (prevOrder + nextOrder) / 2
      }
      return { order, prevOrder, nextOrder }
    },
    checkMove (evt) {
      const newIndex = evt.moved.newIndex
      let list = cloneDeep(this[this.listName])
      let { order, prevOrder, nextOrder } = this.getOrderAndPrevOrderAndNextOrder(list, newIndex)

      order = this.getOrder(order, prevOrder, nextOrder)

      if (this.getDecimalPlacesCount(order) > INDEX_ORDER_MAX_DECIMAL_PLACES) {
        list = this.resetOrdering()
      } else {
        this.$set(list[newIndex], this.orderFieldName, order)
      }
      // this.printLog(order, prevOrder, nextOrder, roundDown, roundUp)
      this.$set(this, this.listName, list)
    }
  }
}
</script>
