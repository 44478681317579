<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          no-local-sorting
          show-empty
          striped
          hover
          responsive
          :sticky-header="stickyHeader"
          :items="list"
          :fields="fields"
        >
          <template slot="empty">
            <div class="text-center">
              {{ noResultsLabel | translate }}
            </div>
          </template>
          <template v-if="showChecks" :slot="`head(checks)`" slot-scope="data">
            <thux-table-checks-column
              :data="data"
              :length="list ? list.length : 0"
              :getLocalSelectedAll="getLocalSelectedAll"
              @check-all="$emit('check-all')"
              @check-none="$emit('check-none')"
            />
          </template>
          <template
            v-for="column in getColumns"
            :slot="`head(${column})`"
            slot-scope="data"
          >
            <slot v-if="data" :name="`head(${column})`">
              <div class="table-sort">
                <i
                    v-if="data.field && data.field.sortable && orderByDict[column]"
                    class="fas fa-sort mr-2 link-item"
                    :title="$t('Remove ordering')"
                    @click="$emit('remove-order-by', column)"
                />
                <div class="table-sort-label" @click="$emit('sort-changed', column)">
                  <span>{{ data.label }}</span>
                  <div v-if="orderByDict[column]" class="mx-2">
                    {{orderByDict[column].index}}
                  </div>
                  <i
                      v-if="orderByDict[column]"
                      class="link-item"
                      :title="$t('Change ordering')"
                     :class="orderByDict[column].sortDesc ? 'fas fa-sort-down pb-2' : 'fas fa-sort-up pt-2'"
                  />
                </div>
              </div>
            </slot>
          </template>
          <template v-for="cell in fields" :slot="`cell(${cell ? cell.key : 'default'})`" slot-scope="row">
            <div v-if="checkCondition(row.item, cell)" :key="cell.key">
              <span v-if="cell.type === 'text'" :class="cell.labelClass">{{
                getValue(row.item, cell) | translate
              }}</span>
              <span v-else-if="cell.type === 'date'" :class="cell.labelClass">{{
                getValue(row.item, cell) | date
              }}</span>
              <span
                v-else-if="cell.type === 'datetime'"
                :class="cell.labelClass"
                >{{ getValue(row.item, cell) | datetime }}</span
              >
              <span
                v-else-if="cell.type === 'stripHtml&truncate'"
                :title="getValue(row.item, cell) | striphtml"
                :class="cell.labelClass"
                >{{ getValue(row.item, cell) | striphtml | truncate }}</span
              >
              <span
                v-else-if="cell.type === 'stripHtml'"
                :class="cell.labelClass"
                >{{ getValue(row.item, cell) | striphtml }}</span
              >
              <span
                v-else-if="cell.type === 'truncate'"
                :title="getValue(row.item, cell)"
                :class="cell.labelClass"
                >{{ getValue(row.item, cell) | truncate }}</span
              >
              <span
                v-else-if="cell.type === 'duration'"
                :class="cell.labelClass"
                >{{ getValue(row.item, cell) | duration(cell.showDays, cell.showHours) }}</span
              >
              <span
                v-else-if="cell.type === 'boolean'"
                :class="cell.labelClass"
              >
                <status-indicator :status="getValue(row.item, cell)" />
              </span>
              <span v-else>{{ getValue(row.item, cell) }}</span>
            </div>
            <slot v-else :name="cell.key" :item="row.item" />
          </template>
          <template slot="cell(action)" slot-scope="row">
            <buttons-actions-thux
              :instance-name="instanceName"
              :item="row.item"
              :id="row.item[fieldName]"
              :show-edit-button="showEditButton"
              :show-detail-button="showDetailButton"
              @open-detail-form="$emit('open-detail-form', $event)"
              @open-edit-form="$emit('open-edit-form', $event)"
            >
              <template slot="other-buttons-top">
                <slot
                  name="other-buttons-actions-top"
                  :item="row.item"
                  :index="row.index"
                />
              </template>
              <template slot="other-buttons-bottom">
                <slot
                  name="other-buttons-actions-bottom"
                  :item="row.item"
                  :index="row.index"
                />
              </template>
            </buttons-actions-thux>
          </template>
          <template v-if="showChecks" slot="cell(checks)" slot-scope="data">
            <thux-table-checks-component
              :select-all="selectAll"
              :value="getLocalSelectedAll ? true : checkLocalSelectedList(data)"
              @set-selected-row="$emit('set-selected-row', data.item)"
            />
          </template>
          <template slot="cell(status)" slot-scope="row">
            <status-indicator :status="row.item.status" />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ButtonsActionsThux from './thux-button-actions/ButtonsActionsThux'
import ThuxTableChecksColumn from './thux-table-checks/ThuxTableChecksColumn'
import ThuxTableChecksComponent from './thux-table-checks/ThuxTableChecksComponent'
import StatusIndicator from '../StatusIndicator'

export default {
  name: 'ThuxTableComponent',
  props: {
    instance: { required: true },
    instanceName: { required: true },
    goBack: { default: false },
    list: { required: true },
    fieldName: { default: 'id' },
    fields: { required: true },
    showChecks: { default: false },
    getLocalSelectedAll: { default: false },
    localSelectedList: { default: Array },
    selectAll: {},
    selectedList: {},
    showEditButton: { default: false },
    showDetailButton: { default: false },
    stickyHeader: { default: false },
    orderByDict: { default: {} },
    noResultsLabel: { default: 'There are no records to show' }
  },
  components: {
    'status-indicator': StatusIndicator,
    'buttons-actions-thux': ButtonsActionsThux,
    'thux-table-checks-column': ThuxTableChecksColumn,
    'thux-table-checks-component': ThuxTableChecksComponent
  },
  computed: {
    getColumns () {
      if (this.fields && this.fields.length > 0) {
        const columnFields = this.fields.filter((field) => { return !!field.sortable })
        return columnFields.map((column) => { return column.key })
      }
      return []
    }
  },
  methods: {
    checkLocalSelectedList (data) {
      return this.localSelectedList.some((item) => {
        return data.item[this.fieldName] === item[this.fieldName]
      })
    },
    checkCellName (key) {
      return key !== 'checks' && key !== 'status' && key !== 'actions'
    },
    checkCondition (item, cell) {
      if (this.checkCellName(cell.key)) {
        if (cell.custom === true) return false
        else if (!cell.condition) return true
        else return item[cell.condition]
      } else {
        return false
      }
    },
    getValue (item, cell) {
      if (cell.condition) {
        return item[cell.condition][cell.value]
      } else {
        return item[cell.value] ? item[cell.value] : ''
      }
    }
  }
}
</script>
