<template>
  <b-form-row>
    <form-thux-horizontal-autocomplete
      :label-form="''"
      class-form="col-10 mb-2 mt-2"
      labelColsSm="0"
      labelColsLg="0"
      :disabled="false"
      :label-search="'name'"
      :value="form.id ? { id: form.id, name: form.name } : null"
      :options="typologyServicesOptions"
      :placeholder="$t('Type a category')"
      :placeholder-field="form.name"
      @search="onSearchTypology"
      @select="selectTypology"
      @clear="clearTypology"
    />
    <div class="col-2 d-flex align-items-center">
      <b-btn
          size="lg"
          variant="outline-primary"
          :disabled="$v.form.$invalid"
          @click="onSubmit()"
          class="w-100"
      >
        <i class="fas fa-plus mr-1"></i>
        {{ 'Add' | translate }}
      </b-btn>
    </div>
  </b-form-row>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import FormThuxHorizontalAutocomplete from '../../../../../components/form-thux-horizontal-components/FormThuxHorizontalAutocomplete'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { TYPES as PRODUCT_CATEGORY_TYPES } from '../../../../product/product-category/store'
import { vue } from '../../../../../main'

export default {
  name: 'ServiceTypologySelect',
  props: {
    typologiesSelected: { default: Array }
  },
  components: {
    'form-thux-horizontal-autocomplete': FormThuxHorizontalAutocomplete
  },
  data () {
    return {
      form: {}
    }
  },
  created () {
    this.$set(this, 'form', {})
    this.setProductCategoryList({})
  },
  computed: {
    ...mapGetters({
      ...PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.GETTERS
    }),
    typologyServicesOptions () {
      const typologyOptions = this.list && this.list.results ? this.list.results : []
      if (this.typologiesSelected && this.typologiesSelected.length > 0) {
        const typologyOptionsFiltered = []
        typologyOptions.forEach((typology) => {
          const isTypologySelected = this.typologiesSelected.some((service) => {
            return typology.id.toString() === service.id.toString()
          })
          if (!isTypologySelected) {
            typologyOptionsFiltered.push({
              id: typology.id,
              name: typology.name,
              is_formation: typology.type_of_sale === 'UN'
            })
          }
        })
        return typologyOptionsFiltered
      }
      return typologyOptions.map((typology) => {
        return {
          id: typology.id,
          name: typology.name,
          is_formation: typology.type_of_sale === 'UN'
        }
      })
    }
  },
  methods: {
    ...mapMutations({
      setProductCategoryList: PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...PRODUCT_CATEGORY_TYPES.GENERIC.product.productcategory.LIST.ACTIONS
    }),
    onSearchTypology (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.setFilters(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductCategoryList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectTypology (item) {
      this.$set(this.form, 'id', item.id)
      this.$set(this.form, 'name', item.name)
      this.$set(this.form, 'is_formation', item.is_formation)
    },
    clearTypology () {
      this.$set(this.form, 'id', null)
      this.$set(this.form, 'name', null)
      this.$set(this.form, 'is_formation', null)
    },
    onSubmit () {
      if (!this.$v.form.$invalid) {
        this.$emit('submit-typology', this.form)
        this.$set(this, 'form', {})
      }
    }
  },
  validations: {
    form: {
      id: { required },
      name: { required }
    }
  }
}
</script>
