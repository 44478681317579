<template>
  <panel :title="'Quotation data' | translate" noButton="true">
    <b-form>
      <b-form-row v-if="showOldQuotationCheckBox">
        <form-thux-horizontal-checkbox
          :validator="$v.checkbox.isOldQuotation"
          :label-form="'Existing quotation' | translate"
          class-form="col-6 mb-2"
          label-cols-lg="3"
          label-cols-sm="3"
          :value="isOldQuotation"
          @change="changeOldQuotation($event)"
        />
        <form-thux-horizontal-input
          v-if="isOldQuotation"
          :validator="$v.form.number"
          :external-errors="errors['number']"
          :label-form="'Quotation Number' | translate"
          class-form="col-6 my-2"
          label-cols-lg="3"
          label-cols-sm="3"
          type="number"
          :value="form.number"
          :disabled="!formEditable"
          @change="changeQuotationnumber($event)"
          :description="quotationNumberFieldDesc"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.date_q"
          :label-form="'Date' | translate"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="!formEditableUpdate"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="dateQ"
          :placeholder="$t('Enter the date')"
          @change="setDateQ"
        />
        <form-thux-horizontal-datetime
          :validator="$v.form.date_firm_for"
          :label-form="'Quotation deadline' | translate"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="!formEditableUpdate"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="dateFirmFor"
          :min-date="form.date_q"
          :placeholder="$t('Enter the date')"
          @change="dateChange($event, 'date_firm_for')"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-multiselect
          v-if="isYardQuotation"
          :validator="$v.form.clients"
          :external-errors="errors['clients']"
          :label-form="'Yard Clients' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="(this.proposal && this.proposal.clients) || !formEditableUpdate"
          :label-search="'name'"
          :value="form.clients"
          :options="clientsListOptions"
          :placeholder="
            $t('Select a {instance}', { instance: $t('clients') })
          "
          @search="onSearchClients"
          @remove="removeClients"
          @select="selectClients"
          @clear="$set(row, 'clients', null)"
        />
        <form-thux-horizontal-autocomplete
          :validator="$v.form.customer"
          :label-form="'Holder' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          :disabled="this.proposal.id !== undefined || !formEditableUpdate"
          :label-search="'text'"
          labelColsSm="3"
          labelColsLg="3"
          :value="form.customer ? { value: form.customer, text: form.customer_typology_name ? `${form.customer_name} (${form.customer_typology_name})` : form.customer_name } : null"
          :options="customerListOptions"
          :placeholder="$t('Type a customer')"
          :placeholder-field="form.customer_name"
          @search="onSearchCustomer"
          @select="selectQuotationCustomer"
          @clear="clearQuotationCustomer"
        />
      </b-form-row>
      <referents-manager
        :form-editable-update="formEditableUpdate"
        :supplier-contacts="supplierContacts"
        @update-referents="$set(form, 'supplier_contacts', $event ? $event : undefined)"
      />
      <b-form-row>
        <form-thux-horizontal-text-area
          :validator="$v.form.description"
          :label-form="'Object' | translate"
          class-form="col-12 mb-2 mt-2"
          label-form-class="col-half-width"
          labelColsSm="4"
          labelColsLg="3"
          rows="3"
          max-rows="6"
          :value="form.description"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'description', $event)"
        />
      </b-form-row>
      <b-form-row v-if="form.customer_typology === 'PA'">
        <form-thux-horizontal-input
          :validator="$v.form.recipient_document_number"
          :external-errors="errors['recipient_document_number']"
          :label-form="'Recipient document number' | translate"
          class-form="col-6 mb-2"
          label-cols-lg="3"
          label-cols-sm="3"
          type="number"
          :value="form.recipient_document_number"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'recipient_document_number', $event)"
        />
        <form-thux-horizontal-datetime
          :validator="$v.form.recipient_document_date"
          :label-form="'Recipient document date' | translate"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="!formEditableUpdate"
          class-form="col-lg-6 mb-2 mt-2"
          type="date"
          :value="recDocumentDate"
          :min-date="form.recipient_document_date"
          :placeholder="$t('Enter the date')"
          @change="dateChange($event, 'recipient_document_date')"
        />
      </b-form-row>
      <hr>
        <b-button class="mr-2" :disabled="!formEditableUpdate" size="md" variant="primary" @click.prevent="openSelectExtraModuleModal()">
          <i class="fas fa-map-pin mr-1"></i>
          <span v-if="!form.quotation_extra_module">{{ 'Select extra module' | translate }}</span>
          <span v-else>{{ 'Change extra module' | translate }}</span>
        </b-button>
        <b-button class="mr-2" v-if="form.quotation_extra_module" :disabled="!formEditableUpdate" size="md" variant="primary" @click.prevent="clearQuotationExtraModule()">
          <i class="fas fa-map-pin mr-1"></i>
          <span>{{ 'Remove extra module' | translate }}</span>
        </b-button>
      <b-form-row v-if="form.quotation_extra_module">
        <form-thux-horizontal-input
          :validator="$v.form.extra_module_title"
          :external-errors="errors['extra_module_title']"
          :label-form="'Extra module title' | translate"
          class-form="col-12 mb-2 mt-2"
          label-form-class="col-half-width"
          type="text"
          :value="form.extra_module_title"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'extra_module_title', $event)"
        />
        <form-thux-horizontal-ckeditor
          v-if="form.quotation_extra_module"
          :validator="$v.form.extra_content"
          :label-form="'Extra Content' | translate"
          class-form="col-12 mb-2 mt-2"
          label-form-class="col-half-width"
          :value="form.extra_content"
          :disabled="!formEditableUpdate"
          @change="$set(form, 'extra_content', $event)"
        />
      </b-form-row>
    </b-form>
    <select-extra-module-modal v-if="showSelectExtraModuleModal" v-on:close-select-extra-module="closeSelectExtraModuleModal"></select-extra-module-modal>
  </panel>
</template>

<script>
import { vue } from '../../../../../main'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import CustomerMixin from '../../mixins/CustomerMixin'
import ReferentMixin from '@/components/owner-organization-referents/mixins/ReferentMixin'
import ReferentsManager from '@/components/owner-organization-referents/ReferentsManager'
import { TYPES as QUOTATION_TYPES } from '../../store'
import { TYPES as QUOTATION_EXTRA_MODULE_TYPES } from '../../../quotation-extra-module/store'
import SelectExtraModuleModal from './SelectExtraModuleModal'
import moment from 'moment'
import ClientsMixin from '../../../../proposal/proposal/mixins/ClientsMixin.vue'

export default {
  name: 'QuotationEditForm',
  mixins: [ThuxDetailPanelMixin, CustomerMixin, ReferentMixin, ClientsMixin],
  components: {
    ReferentsManager,
    SelectExtraModuleModal
  },
  props: {
    proposal: undefined,
    isYardQuotation: undefined,
    formEditableUpdate: {},
    quotationextramoduleLength: {},
    resetForm: {},
    errors: {}
  },
  watch: {
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    proposal () {
      this.init()
    },
    resetForm () {
      this.init()
    },
    id () {
      this.init()
    }
  },
  data () {
    return {
      dateQ: undefined,
      dateFirmFor: undefined,
      recDocumentDate: undefined,
      supplierContacts: [],
      showOldQuotationCheckBox: false,
      isOldQuotation: false,
      showSelectExtraModuleModal: false,
      quotationNumberFieldDesc: this.$t('If the number is not entered, the system will assign a number based on the budget numbering of the reference year. The number cannot be changed at a later time')
    }
  },
  created () {
    this.setQuotationExtraModuleList({})
    this.init()
  },
  computed: {
    ...mapGetters({
      detail: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.GETTERS.detail,
      ...QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.GETTERS
    }),
    quotationextramoduleListOptions () {
      const options = []
      const quotationextramoduleList = this.list && this.list.results ? this.list.results : []
      quotationextramoduleList.forEach((quotationextramodule) => {
        const option = {
          id: quotationextramodule.id,
          name: quotationextramodule.name,
          content: quotationextramodule.content,
          status: quotationextramodule.status
        }
        if (option.status === 0) {
          option.$isDisabled = true
        }
        options.push(option)
      })
      return options
    }
  },
  methods: {
    ...mapMutations({
      setQuotationExtraModuleList: QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.ACTIONS
    }),
    init () {
      if (this.proposal && this.proposal.number) {
        const form = {}
        this.$set(form, 'clients', this.proposal.clients)
        this.$set(form, 'customer', this.proposal.owner)
        this.$set(form, 'customer_name', this.proposal.owner_name)
        this.$set(form, 'description', this.proposal.work_subject)
        this.$set(form, 'supplier_contacts', this.proposal.supplier_contacts.map((contact) => contact.id))
        this.$set(this, 'supplierContacts', this.proposal.supplier_contacts)
        this.$set(this, 'showOldQuotationCheckBox', false)
        if (form.clients && form.clients.length > 0) {
          const filters = {}
          filters.filter__id__in = [form.clients]
          this.getClientsList(filters).then(() => {
            this.$set(form, 'clients', this.getClients(form))
            this.$set(this, 'form', form)
          })
        } else {
          this.$set(this, 'form', form)
        }
      } else if (this.id) {
        this.makeFormReadonly()
        this.setForm()
        this.$set(this, 'showOldQuotationCheckBox', false)
      } else {
        this.makeFormEditable()
        this.$set(this, 'showOldQuotationCheckBox', true)
      }
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      if (this.detail.date_q) {
        this.$set(this, 'dateQ', moment(new Date(this.detail.date_q)))
      }
      if (this.detail.date_firm_for) {
        this.$set(this, 'dateFirmFor', moment(new Date(this.detail.date_firm_for)))
      }
      if (this.detail.recipient_document_date) {
        this.$set(this, 'recDocumentDate', moment(new Date(this.detail.recipient_document_date)))
      }
      this.$set(this, 'supplierContacts', this.detail.supplier_contacts)
      this.$set(this.form, 'supplier_contacts', this.detail.supplier_contacts.map((contact) => contact.id))
      this.$set(this, 'form', form)
      if (form.clients && form.clients.length > 0) {
        const filters = {}
        filters.filter__id__in = [form.clients]
        this.getClientsList(filters).then(() => {
          this.$set(form, 'clients', this.getClients(form))
          this.$set(this, 'form', form)
        })
      } else {
        this.$set(this, 'form', form)
      }
    },
    setDateQ (evt) {
      const date = evt.date
      if (date) {
        this.$set(this.form, 'date_q', date.format('YYYY-MM-DD'))
      } else {
        this.$set(this.form, 'date_q', undefined)
      }
      if (this.dateFirmFor && date > this.dateFirmFor) {
        this.$set(this.form, 'date_firm_for', undefined)
        this.$set(this, 'dateFirmFor', null)
      }
    },
    changeOldQuotation (evt) {
      this.$set(this, 'isOldQuotation', evt)
      if (!evt) {
        this.$set(this.form, 'number', null)
      }
      this.$emit('create-old-quotation', evt)
    },
    changeQuotationnumber (evt) {
      this.$set(this.form, 'number', evt)
      if (!evt) {
        this.$set(this, 'quotationNumberFieldDesc', this.$t('If the number is not entered, the system will assign a number based on the budget numbering of the reference year. The number cannot be changed at a later time'))
      } else {
        this.$set(this, 'quotationNumberFieldDesc', this.$t('The number indicated will be assigned to the generated estimate and no checks will be carried out on its correctness. The number CANNOT be changed at a later time'))
      }
    },
    onSearchQuotationExtraModule (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.filter__status = 1
        this.setFilters(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setQuotationExtraModuleList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    clearQuotationExtraModule () {
      this.$set(this.form, 'quotation_extra_module', null)
      this.$set(this.form, 'extra_module_title', null)
      this.$set(this.form, 'extra_content', null)
    },
    openSelectExtraModuleModal () {
      this.showSelectExtraModuleModal = true
    },
    closeSelectExtraModuleModal (evtData) {
      if (evtData) {
        this.$set(this.form, 'quotation_extra_module', evtData.id)
        this.$set(this.form, 'extra_module_title', evtData.name)
        this.$set(this.form, 'extra_content', evtData.content)
        this.selectedAddress = evtData
      }
      this.showSelectExtraModuleModal = false
    },
    selectQuotationCustomer (item) {
      this.$set(this.form, 'customer', item.value)
      this.$set(this.form, 'customer_name', item.text)
      this.$set(this.form, 'customer_typology', item.typology)
      if (item.typology !== 'PA') {
        this.$set(this.form, 'recipient_document_number', null)
        this.$set(this.form, 'recipient_document_date', null)
      }
      this.$emit('change-owner')
    },
    clearQuotationCustomer () {
      this.$set(this.form, 'customer', null)
      this.$set(this.form, 'customer_name', null)
      this.$set(this.form, 'customer_typology', null)
      this.$set(this.form, 'recipient_document_number', null)
      this.$set(this.form, 'recipient_document_date', null)
    }
  },
  validations () {
    const validator = {
      checkbox: {
        isOldQuotation: { }
      },
      form: {
        number: { },
        clients: { },
        date_q: { required },
        date_firm_for: { required },
        supplier_contacts: {},
        customer: { required },
        phone_number: {},
        email: {},
        quotation_extra_module: {},
        extra_module_title: {},
        description: { required },
        recipient_document_number: {},
        recipient_document_date: {},
        extra_content: {}
      }
    }
    if (this.form.quotation_extra_module) {
      validator.form.extra_module_title = { required }
      validator.form.extra_content = { required }
    }
    if (this.isYardQuotation) {
      validator.form.clients = { required }
    } else {
      validator.form.clients = { }
    }
    return validator
  }
}
</script>
