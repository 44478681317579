<script>
export default {
  name: 'ThuxButtonEditMixin',
  computed: {
    showEditButton () {
      return false
    }
  }
}
</script>
