<template>
  <div class="row">
    <draggable
      :scroll-sensitivity="250"
      :fallback-tolerance="1"
      :force-fallback="true"
      class="col-12 p-0"
      v-model="products"
      @change="checkMove"
      :disabled="!formEditableUpdate"
    >
      <div class="service-item" v-for="(product, index) in products" :key="product ? product.id : product">
        <service-product-form
          :form-editable-update="formEditableUpdate"
          :form-update="product"
          :show-add-button="index === products.length - 1"
          :products-length="products.length"
          :index="index"
          :typology="service.typology"
          @add-product="addNewProduct"
          @edit-product="editProduct($event, index)"
          @remove-product="removeProduct($event, index)"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import ServiceProductForm from './ServiceProductForm'
import draggable from 'vuedraggable'
import VueDraggableMixin from '../../../../../components/order/VueDraggableMixin'

export default {
  name: 'ServiceProductsManager',
  mixins: [VueDraggableMixin],
  props: {
    service: { required: true, type: Object },
    formEditableUpdate: {}
  },
  components: {
    'service-product-form': ServiceProductForm,
    draggable
  },
  watch: {
    service () {
      this.init()
    },
    products: {
      handler () {
        this.$emit(
          'update-products',
          { products: this.products, productToDeleted: this.productToDeleted, productToChanged: this.productToChanged }
        )
      },
      deep: true
    }
  },
  data () {
    return {
      products: [{}],
      productToDeleted: null,
      productToChanged: null,
      listName: 'products',
      orderFieldName: 'row_order'
    }
  },
  created () {
    this.init()
  },
  computed: {
    getProductsIndex () {
      if (this.products && this.products.length > 0) {
        return this.products.map(product => product.row_order)
      }
      return []
    }
  },
  methods: {
    init () {
      if (this.service.products.length > 0) {
        this.$set(this, 'products', this.service.products)
      } else {
        this.$set(this, 'products', [{ row_order: 1 }])
      }
    },
    addNewProduct () {
      let rowOrder = 1
      if (this.products[this.products.length - 1]) {
        rowOrder = parseInt(this.products[this.products.length - 1].row_order) + 1
      }
      this.products.push({ row_order: rowOrder })
    },
    editProduct (product, index) {
      this.$set(this, 'productToDeleted', null)
      this.$set(this, 'productToChanged', product.id)
      this.$set(this.products, index, product)
    },
    removeProduct (product, index) {
      this.$set(this, 'productToDeleted', product.id)
      this.$set(this, 'productToChanged', null)
      this.products.splice(index, 1)
    }
  }
}
</script>
