<template>
  <div class="panel-services-manager">
    <panel :title="'Quotation Installments' | translate" noButton="true" panel-class="panel-services">
      <div class="col-12 installment-items px-0" v-for="(installment, index) in installments" :key="index">
        <quotation-installment-form
          :form-editable-update="formEditableUpdate"
          :form-update="installment"
          :total-amount="totalAmount"
          :total-income="totalIncome"
          :show-add-button="index === installments.length - 1"
          :installment-length="installments.length"
          :is-create-old-quotation="isCreateOldQuotation"
          :index="index"
          @add-installment="addNewInstallment"
          @edit-installment="editInstallment($event, index)"
          @remove-installment="removeInstallment($event, index)"
        />
      </div>
    </panel>
  </div>
</template>

<script>
import QuotationInstallmentForm from './QuotationInstallmentForm'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

export default {
  name: 'QuotationInstallmentManager',
  props: {
    totalAmount: {},
    totalIncome: {},
    formEditableUpdate: {},
    quotationInstallments: [],
    resetQuotationInstallments: {},
    isCreateOldQuotation: undefined
  },
  components: {
    'quotation-installment-form': QuotationInstallmentForm
  },
  watch: {
    installments: {
      handler () {
        let installments = [...this.installments]
        installments = installments.filter((installment) => {
          return Object.values(installment).some((value) => { return !!value })
        })
        this.$emit(
          'update-installments',
          installments,
          this.installmentsToDeleted,
          this.installmentsToChanged
        )
      },
      deep: true
    },
    resetQuotationInstallments () {
      this.init()
    },
    quotationInstallments () {
      this.init()
    }
  },
  data () {
    return {
      installments: [{}],
      installmentsToDeleted: new Set(),
      installmentsToChanged: new Set()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.quotationInstallments && this.quotationInstallments.length > 0) {
        this.$set(this, 'installments', cloneDeep(this.quotationInstallments))
      } else {
        this.$set(this, 'installments', [{}])
      }
      this.$set(this, 'installmentsToDeleted', new Set())
      this.$set(this, 'installmentsToChanged', new Set())
    },
    addNewInstallment () {
      this.installments.push({ printable: true })
    },
    editInstallment (installment, index) {
      if (installment.id) {
        this.installmentsToChanged.add(installment.id)
      }
      this.$set(this.installments, index, installment)
    },
    removeInstallment (installment, index) {
      if (installment.id) {
        this.installmentsToDeleted.add(installment.id)
        this.installmentsToChanged.delete(installment.id)
      }
      if (this.installments.length > 1) {
        this.installments.splice(index, 1)
      } else {
        this.$set(this, 'installments', [{}])
      }
    }
  },
  validations: {
    installments: {
      $each: {
        name: { required },
        amount: { required },
        days: { },
        days_end_month: { },
        end_month: { },
        vat: { required }
      }
    }
  }
}
</script>
