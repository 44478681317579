<template>
  <div class="d-flex align-items-center">
    <input
      v-show="length && getLocalSelectedAll && data"
      type="checkbox"
      readonly
      :checked="true"
      @click.prevent="$emit('check-none')"
    />
    <input
      v-show="length && !getLocalSelectedAll && data"
      type="checkbox"
      :checked="false"
      readonly
      @click.prevent="$emit('check-all')"
    />
  </div>
</template>

<script>
export default {
  name: 'ThuxTableChecksColumn',
  props: {
    data: { required: true },
    length: { required: true },
    getLocalSelectedAll: { required: true }
  }
}
</script>
