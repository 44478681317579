<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PAYMENT_FORM_TYPES } from '../../../payment/payment-form/store'
import { vue } from '../../../../main'

export default {
  name: 'PaymentFormMixin',
  props: {
    paymentFormsUpdate: {}
  },
  watch: {
    paymentFormsUpdate: {
      handler () {
        this.$set(this, 'paymentForms', this.paymentFormsUpdate)
      },
      deep: true
    },
    paymentForms: {
      handler () {
        this.$emit('update-form', this.paymentForms)
      },
      deep: true
    }
  },
  data () {
    return {
      paymentForms: []
    }
  },
  created () {
    this.setList({})
  },
  computed: {
    ...mapGetters({
      paymentFormList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.GETTERS.list
    }),
    paymentFormListOptions () {
      const options = []
      if (this.paymentFormList && this.paymentFormList.results && this.paymentFormList.results.length > 0) {
        this.paymentFormList.results.forEach((paymentForm) => {
          const option = {
            id: paymentForm.id,
            name: paymentForm.name,
            description: paymentForm.description,
            status: paymentForm.status
          }
          if (paymentForm.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getPaymentFormList: PAYMENT_FORM_TYPES.GENERIC.payment.paymentform.LIST.ACTIONS.setFilters
    }),
    onSearchPaymentForm (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getPaymentFormList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectPaymentForm (item) {
      if (!this.paymentForms) {
        this.$set(this, 'paymentForms', [item])
      } else {
        this.paymentForms.push(item)
      }
    },
    removePaymentForm (item) {
      let paymentForms = this.paymentForms.filter((paymentForm) => {
        return paymentForm.id !== item.id
      })
      if (paymentForms.length === 0) paymentForms = null
      this.$set(this, 'paymentForms', paymentForms)
    },
    clearPaymentForm () {
      this.$set(this, 'paymentForms', null)
    }
  }
}
</script>
