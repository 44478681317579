<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PRODUCT_TYPES } from '../store'

export default {
  name: 'VatMixin',
  created () {
    if (!this.vatList || (this.vatList && this.vatList.length === 0)) {
      this.getVatList()
    }
  },
  computed: {
    ...mapGetters({
      vatList: PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS.vatList
    }),
    vatListOptions () {
      const options = []
      if (this.vatList && this.vatList.length > 0) {
        this.vatList.forEach((element) => {
          const option = {
            id: element.code,
            name: element.name,
            aliquot_perc: parseInt(element.aliquot_perc).toString(),
            status: element.status
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getVatList: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.getVatlist
    }),
    selectVat (item) {
      this.$set(this.form, 'vat', item.id)
      this.$set(this.form, 'vat_name', item.name)
      this.$set(this.form, 'vat_aliquot_perc', item.aliquot_perc)
    },
    clearVat () {
      this.$set(this.form, 'vat', null)
      this.$set(this.form, 'vat_name', null)
      this.$set(this.form, 'vat_aliquot_perc', null)
    }
  }
}
</script>
