<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :show-checks="showChecks"
    :show-edit-button="showEditButton"
    :show-detail-button="showDetailButton"
    @change-status="changeInstanceStatus"
    @remove-order-by="removeOrderFromTable"
    @sort-changed="orderTable"
    @open-edit-form="$emit('open-edit-form', $event)"
    @open-detail-form="$emit('open-detail-form', $event)"
  />
</template>

<script>
import ThuxButtonEditMixin from './thux-button-actions/mixins/ThuxButtonEditMixin'
import ThuxTableComponent from './ThuxTableComponent'
import ThuxButtonDetailMixin from './thux-button-actions/mixins/ThuxButtonDetailMixin'

export default {
  name: 'ThuxTableMixin',
  mixins: [
    ThuxButtonEditMixin,
    ThuxButtonDetailMixin
  ],
  props: {
    list: {},
    params: { default: Object },
    goBack: { default: false }
  },
  components: {
    'thux-table': ThuxTableComponent
  },
  watch: {
    selectedList: function () {
      this.$set(this, 'localSelectedList', this.selectedList)
    }
  },
  data () {
    return {
      instance: {},
      instanceName: '',
      route: '',
      fieldName: 'id',
      fields: [],
      orderByDict: {}
    }
  },
  computed: {
    showChecks () {
      return false
    }
  },
  methods: {
    removeOrderFromTable (orderBy) {
      delete this.orderByDict[orderBy]
      const orderByListString = Object.values(this.orderByDict).map(
        value => `${value.sortDesc ? '-' : ''}${value.orderBy}`
      )
      let index = 1
      Object.keys(this.orderByDict).forEach((key) => {
        this.orderByDict[key].index = index
        index += 1
      })
      this.changeOrderBy(orderByListString.join('.'))
    },
    orderTable (orderBy) {
      if (orderBy) {
        if (this.orderByDict[orderBy]) {
          this.orderByDict[orderBy].sortDesc = !this.orderByDict[orderBy].sortDesc
        } else {
          this.orderByDict[orderBy] = {
            orderBy: orderBy,
            sortDesc: false,
            index: Object.keys(this.orderByDict).length + 1
          }
        }
        const orderByListString = Object.values(this.orderByDict).map(
          value => `${value.sortDesc ? '-' : ''}${value.orderBy}`
        )
        this.changeOrderBy(orderByListString.join('.'))
      }
    },
    changeInstanceStatus (instance, fieldName = 'id') {
      this.setStatus({ id: instance[fieldName], status: instance.status === 1 ? 0 : 1 })
    }
  }
}
</script>
