<template>
  <div class="panel-detail panel-fixed">
    <div class="panel-header">
      <h1 class="page-header">
        {{ `${$t('Create quotation from proposal')}` }}
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span @click="goToList" class="underline-on-hover">
            {{ 'Proposals' | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ 'Create quotation' | translate }}
          </span>
        </li>
      </ol>
    </div>
    <quotation-edit />
  </div>
</template>

<script>
import { ROUTES as PROPOSAL_ROUTES } from './router'
import QuotationEdit from '../../quotation/quotation/QuotationEdit.vue'

export default {
  name: 'ProposalToQuotation',
  components: {
    'quotation-edit': QuotationEdit
  },
  data () {
    return {
      pageName: 'Proposal to quotation',
      form: {}
    }
  },
  methods: {
    goToList () {
      this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_LIST })
    }
  }
}
</script>
