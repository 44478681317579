<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-text-area
        :validator="$v.form.name"
        :label-form="'Name' | translate"
        class-form="input-row-col my-2 pl-2"
        type="text"
        :value="$v.form.name.$model"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate || form.is_billed"
        @change="$set(form, 'name', $event)"
      />
      <form-thux-horizontal-select
        :validator="$v.form.type"
        :label-form="'Typology' | translate"
        class-form="input-row-col my-2 pl-2"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate || form.is_billed"
        label-search="name"
        :value="form.type ? { id: form.type, name: form.get_type_display } : null"
        :placeholder="$t('Select an option')"
        :options="typologyChoicesListOptions"
        @select="selectTypologyChoices"
        @clear="clearTypologyChoices"
      />
      <form-thux-horizontal-input
        :validator="$v.form.amount_perc"
        :label-form="'Quote' | translate"
        class-form="input-row-col input-col-sm my-2 pl-2"
        type="number"
        maxlength="3"
        :min="0"
        :max="100"
        :value="$v.form.amount_perc.$model"
        placeholder="%"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate || form.is_billed"
        @change="$set(form, 'amount_perc', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.amount"
        :label-form="'Income' | translate"
        class-form="input-row-col my-2 pl-2"
        type="number"
        :min="0"
        :value="$v.form.amount_perc.$model ? getTotalIncome : $v.form.amount.$model"
        placeholder="€"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="!formEditableUpdate ? !formEditableUpdate : (!!$v.form.amount_perc.$model || form.is_billed)"
        @change="$set(form, 'amount', $event)"
      />
      <form-thux-horizontal-autocomplete
        :validator="$v.form.vat"
        :label-form="'VAT' | translate"
        class-form="input-row-col my-2 pl-2"
        :disabled="!formEditableUpdate || form.is_billed"
        :customize-option="true"
        labelColsSm="0"
        labelColsLg="0"
        label-search="name"
        :value="form.vat_name ? { value: $v.form.vat.$model, name: $v.form.vat_name.$model } : null"
        :options="vatListOptions"
        :placeholder="$t('VAT')"
        :placeholder-field="form.vat_name ? form.vat_aliquot_perc : null"
        @select="selectVat"
        @clear="clearVat"
      />
      <form-thux-horizontal-checkbox
        :validator="$v.form.is_billed"
        :label-form="'Billed' | translate"
        class-form="input-row-col my-2 pl-2"
        label-cols-lg="0"
        label-cols-sm="0"
        :value="form.is_billed"
        @change="changeIsBilled($event)"
      />
      <form-thux-horizontal-checkbox
        :validator="$v.form.printable"
        :label-form="'Printable' | translate"
        class-form="input-row-col my-2 pl-2"
        label-cols-lg="0"
        label-cols-sm="0"
        :value="form.printable"
        @change="changePrintable($event)"
      />
      <b-btn-group class="col-xl-1 btn-row-actions my-2 pl-2">
      <b-btn
        v-if="showAddButton"
        class="mr-1"
        variant="primary"
        :disabled="$v.form.$invalid || !formEditableUpdate"
        @click="addInstallment
      ">
        <i class="fas fa-plus"></i>
      </b-btn>
      <b-btn class="mr-1" variant="outline-primary" @click="removeInstallment"
        :disabled="!formEditableUpdate || form.is_billed">
        <i :class="index === 0 && installmentLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
      </b-btn>
    </b-btn-group>
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-datetime
        :validator="$v.form.date_invoice_issue"
        :label-form="'Invoice issue date' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 pl-2"
        type="date"
        :value="invoiceDate"
        :disabled="!formEditableUpdate || form.is_billed"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'date_invoice_issue')"
      />
      <form-thux-horizontal-datetime
        :validator="$v.form.expiration_date"
        :label-form="'Expiration payment date' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 pl-2"
        type="date"
        :value="expirationDate"
        :disabled="!formEditableUpdate || form.is_billed"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'expiration_date')"
      />
    </b-form-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

import ThuxDetailPanelMixin from '../../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import TypologyChoicesMixin from '../../mixins/TypologyChoicesMixin'
import VatMixin from '../../../../product/product/mixins/VatMixin'

export default {
  name: 'QuotationInstallmentForm',
  mixins: [ThuxDetailPanelMixin, TypologyChoicesMixin, VatMixin],
  props: {
    totalAmount: {},
    totalIncome: {},
    formUpdate: {},
    index: {},
    installmentLength: {},
    showAddButton: {},
    formEditableUpdate: {},
    isCreateOldQuotation: undefined
  },
  watch: {
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
          if (this.form.expiration_date) {
            this.$set(this, 'expirationDate', moment(new Date(this.form.expiration_date)))
          }
          if (this.form.date_invoice_issue) {
            this.$set(this, 'invoiceDate', moment(new Date(this.form.date_invoice_issue)))
          }
        }
      },
      deep: true
    }
  },
  data () {
    return {
      form: {},
      expirationDate: undefined,
      invoiceDate: undefined
    }
  },
  computed: {
    getTotalIncome () {
      if (this.totalIncome && this.form.amount_perc) {
        const quotePrice = (parseInt(this.form.amount_perc) * parseFloat(this.totalIncome)) / 100
        this.$set(this.form, 'amount', (quotePrice).toFixed(2))
        return (quotePrice).toFixed(2)
      } else {
        this.$set(this.form, 'amount', null)
        return null
      }
    }
  },
  methods: {
    init () {
      if (this.formUpdate) {
        this.$set(this, 'form', this.formUpdate)
        if (this.form.expiration_date) {
          this.$set(this, 'expirationDate', moment(new Date(this.form.expiration_date)))
        }
        if (this.form.date_invoice_issue) {
          this.$set(this, 'invoiceDate', moment(new Date(this.form.date_invoice_issue)))
        }
      }
      this.$watch('form', () => {
        this.$emit('edit-installment', this.form)
      }, { deep: true })
    },
    checkIfInstallmentEmpty () {
      if (this.form.name && this.form.name !== '') { return false }
      if (this.form.amount_perc && this.form.amount_perc !== '') { return false }
      if (this.form.amount && this.form.amount !== '') { return false }
      if (this.form.expiration_date && this.form.expiration_date !== '') { return false }
      if (this.form.date_invoice_issue && this.form.date_invoice_issue !== '') { return false }
      return true
    },
    removeInstallment () {
      if (this.index > 0 || this.installmentLength > 1) {
        this.$emit('remove-installment', this.form)
      } else {
        const instData = Object.assign({}, this.form)
        this.$set(this, 'form', {})
        this.$set(this, 'expirationDate', null)
        this.$set(this, 'invoiceDate', null)
        if (instData.id) {
          this.$emit('remove-installment', instData)
        }
      }
    },
    addInstallment () {
      this.$emit('add-installment', this.$v.form.$model)
    },
    changeCheckbox (value) {
      if (typeof value === 'boolean') {
        this.$set(this.form, 'end_month', value)
      }
    },
    changeIsBilled (evt) {
      this.$set(this.form, 'is_billed', evt)
    },
    changePrintable (evt) {
      this.$set(this.form, 'printable', evt)
    }
  },
  validations () {
    let form = {
      is_billed: {},
      printable: {},
      name: { required },
      type: {},
      amount: { required },
      amount_perc: { required },
      expiration_date: {},
      date_invoice_issue: {},
      vat: { required },
      vat_aliquot_perc: {},
      vat_name: {}
    }
    if (this.checkIfInstallmentEmpty()) {
      form = {
        is_billed: {},
        printable: {},
        name: {},
        type: {},
        amount: {},
        amount_perc: {},
        expiration_date: {},
        date_invoice_issue: {},
        vat: { }
      }
    } else {
      if (this.form.amount && this.form.amount !== '') {
        form.amount_perc = {}
      }
      if (this.form.amount_perc && this.form.amount_perc !== '') {
        form.amount = {}
        form.amount_perc = { required }
      }
    }
    return { form: form }
  }
}
</script>
